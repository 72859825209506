import { useEffect, useState } from "react";
import { ClientHeader } from "../ClientHeader";
import { fetchClientEmployeesJobs } from "../../../api/ClientApi";
import { Stack } from "@mui/material";
import ClientTracketCardList from "./ClientTracketCardList";

export default function ClientChangeTracker() {
  const [changeTrackerData, setChangeTrackerData] = useState([]);
  const [trackerDataForSorting, setTrackerDataForSorting] = useState([]);
  const [clientSearchTerm, setClientSearchTerm] = useState("");

  useEffect(() => {
    getChangeTrackerData();
  }, [clientSearchTerm]);

  const handleExpand = (index) => {
    let a = changeTrackerData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          expanded: !item.expanded,
        };
      } else {
        return {
          ...item,
          expanded: true,
        };
      }
    });
    setChangeTrackerData(a);
  };

  useEffect(() => {
    if (trackerDataForSorting && changeTrackerData) {
      const recentDates = trackerDataForSorting.reduce((acc, record) => {
        if (
          !acc[record.clientEmployeeJobCode] ||
          new Date(record.date) > new Date(acc[record.clientEmployeeJobCode].date)
        ) {
          acc[record.clientEmployeeJobCode] = record;
        }
        return acc;
      }, {});

      const sortedData = changeTrackerData.slice().sort((a, b) => {
        const dateA = recentDates[a.jobCode]
          ? new Date(recentDates[a.jobCode].date)
          : new Date(0);
        const dateB = recentDates[b.jobCode]
          ? new Date(recentDates[b.jobCode].date)
          : new Date(0);
        return dateB - dateA;
      });
      setChangeTrackerData(sortedData);
    }
  }, [trackerDataForSorting])

  const getChangeTrackerData = async () => {
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: clientSearchTerm || "",
        UserId: Number(localStorage.getItem("userId")),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: true,
        SearchList: [
          {
            clientEmployeeId: localStorage.getItem("clientEmployeeId"),
          },
        ],
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
      };
      let res = await fetchClientEmployeesJobs(obj);
      let data = res?.data.map((item) => {
        return {
          ...item,
          expanded: true,
        };
      });
      setChangeTrackerData(data);
    } catch (error) {
      console.log("error ::::::::", error);
    }
  };

  return (
    <>
      <ClientHeader screenName={"Change Tracker"} clientSearchTerm={clientSearchTerm} setClientSearchTerm={setClientSearchTerm} />
      {changeTrackerData.length == 0 ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          {`When ${localStorage?.OrganizationName} adds you to a Job, they will appear here.`}
        </div>
      ) : (
        <div className="page">
          <div className="page-content" style={{ marginTop: "20px" }}>
            <Stack spacing={2}>
              {changeTrackerData.map((data, index) => {
                return (
                  <ClientTracketCardList
                    updateData={getChangeTrackerData}
                    setTrackerDataForSorting={setTrackerDataForSorting}
                    data={data}
                    handelaccordian={handleExpand}
                    index={index}
                  />
                );
              })}
            </Stack>
          </div>
        </div>
      )}
    </>
  );
}
