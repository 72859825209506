import React, { useState, useEffect } from "react";
import "../assets/css/Sidebar.css";
import HomeIcon from "@mui/icons-material/Home";
import { useHistory, useLocation } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ImageIcon from "@mui/icons-material/Image";
import AddBoxIcon from "@mui/icons-material/AddBox";
// import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
// import EngineeringIcon from "@mui/icons-material/Engineering";
import SupportIcon from "@mui/icons-material/Support";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import ChatIcon from "@mui/icons-material/Chat";
import PlaceIcon from "@mui/icons-material/Place";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListIcon from "@mui/icons-material/List";
import PushPinIcon from "@mui/icons-material/PushPin";
import {
  BusinessCenter as BusinessCenterIcon,
  CreditCard as CreditCardIcon,
  Store as StoreIcon,
  Search as SearchIcon,
  ExpandLess,
  ExpandMore,
  PlayCircleFilledWhite as PlayCircleFilledWhiteIcon,
  DirectionsCar as DirectionsCarIcon,
  DescriptionSharp as DescriptionSharpIcon,
  Person,
  Settings,
  Place,
  InsertDriveFile,
  Videocam,
  Payment,
} from "@material-ui/icons";
import "../assets/css/Menubar.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { getChatGroupDashboard } from "../api/ConnectApi";
import logo from ".././assets/images/logo.png";
import { Close as CloseIcon } from "@material-ui/icons";
import { styled } from "@mui/material/styles";
import PlaceHolderImage from "../assets/images/AvatarImage.jpeg";
import BuildIDLogo from "../assets/images/Build-ID-Transparent.png";
import { BIBlue } from "../assets/buildidColors";
import { fetchClientEmployeeLicenses, getClientApprovedPictureBoard, updateEmployeeLicensesIsPinFlag } from "../api/ClientApi";

const Menu1 = [
  {
    path: "/dashboard",
    icon: <HomeIcon className="icon-font-size" />,
    title: "Home",
  },
  {
    path: "/new-map",
    icon: <Place className="icon-font-size" />,
    title: "New Sites",
  },
  {
    path: "/rewards/store/",
    icon: <StoreIcon className="icon-font-size" />,
    title: "Rewards Store",
  },
];

const Menu2 = [
  {
    path: "/timesheets",
    icon: <InsertDriveFile className="icon-font-size" />,
    title: "Timesheets",
  },
  {
    path: "/expenses",
    icon: <InsertDriveFile className="icon-font-size" />,
    title: "Expenses",
  },
  {
    path: "/resume-builder",
    icon: <InsertDriveFile className="icon-font-size" />,
    title: "Resume Builder",
  },
  {
    path: "/tickets",
    icon: <Payment className="icon-font-size" />,
    title: "Tickets",
  },
  {
    path: "/montages",
    icon: <Videocam className="icon-font-size" />,
    title: "Montages",
  },
];

const Menu3 = [
  {
    path: "/contacts",
    icon: <Person className="icon-font-size" />,
    title: "Builder Network",
  },
  {
    path: "/carpool",
    icon: <DirectionsCarIcon className="icon-font-size" />,
    title: "Carpool",
  },
  {
    path: "/job-postings",
    icon: <BusinessCenterIcon className="icon-font-size" />,
    title: "Job Postings",
  },
  {
    path: "/buy-sell",
    icon: <HandshakeIcon className="icon-font-size" />,
    title: "BuySell",
  },
];
const Menu4 = [
  {
    path: "/companies",
    icon: <BusinessCenterIcon className="icon-font-size" />,
    title: "Companies",
  },
  {
    path: "/manufactures",
    icon: <BusinessCenterIcon className="icon-font-size" />,
    title: "Manufactures",
  },
  {
    path: "/products",
    icon: <BusinessCenterIcon className="icon-font-size" />,
    title: "Products",
  },
  {
    path: "/trades",
    icon: <BusinessCenterIcon className="icon-font-size" />,
    title: "Trades",
  },
];

let clientMenuList = [
  {
    path: "/client",
    icon: <HomeIcon className="icon-font-size" />,
    title: "Home",
  },
  // {
  //   path: "/clientChat",
  //   icon: <QuestionAnswerIcon className="icon-font-size" />,
  //   title: "Chat",
  // },
  // {
  //   path: "/clientNotifications",
  //   icon: <NotificationsIcon className="icon-font-size" />,
  //   title: "Notification",
  // },
  // {
  //   path: "/dashboard",
  //   icon: "",
  //   title: "BuildID",
  // },
];

const ClientListModal = (props) => {
  const history = useHistory();
  const handleClientClick = (client) => {
    props?.onClose();
    // localStorage.setItem("clientName", client?.ClientName);
    localStorage.setItem("OrganizationName", client?.OrganizationName);
    localStorage.setItem("ClientLogo", client?.ClientLogo);
    localStorage.setItem("clientId", client?.ClientId);
    localStorage.setItem("clientEmployeeId", client?.ClientEmployeeId);
    localStorage.setItem("clientAccentColor", client?.AccentColor);
    // history.push(`/client/${client?.ClientId}`);
    history.push("/client");
  };

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <div className="modal-center">
        <div className="modal-card" style={{ width: "450px" }}>
          {/* <div className="modal-card-header-buttons"> */}
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "0px 10px",
            }}>
            <IconButton color="primary" onClick={props?.onClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className="modal-card-body">
            <div style={{ paddingBottom: "20px" }}>
              {JSON.parse(localStorage.getItem("clientList")) &&
                JSON.parse(localStorage.getItem("clientList")).map((client) => {
                  return (
                    <div
                      onClick={() => handleClientClick(client)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}>
                      <Avatar src={client?.ClientLogo || PlaceHolderImage} />
                      <p style={{ marginLeft: "10px" }}>
                        {client?.OrganizationName || ""}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// const MenuList = ({ closeMenu, value }) => {
const MenuList = (props) => {
  const navigate = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { pathname } = location;
  const [clientListModalOpen, setClientListModalOpen] = useState(false);
  const [viewBuildIdSplashScreen, setViewBuildIdSplashScreen] = useState(false);
  // const [clientLicenseData, setClientLicenseData] = useState([]);
  const history = useHistory();

  const clientId = window.location.pathname.match(/\/client\/(\d+)/);
  const selectCurrentItem = async (group) => {
    history.push({
      pathname: "/chat",
      state: {
        from: "New Chat",
        chatGroupId: group?.chatGroupId,
      },
    });
    localStorage.setItem("nrOfUsersInGroup", group?.nrOfUsersInGroup);
    localStorage.setItem("chatGroupId", group?.chatGroupId);
  };
  const navigateAll = async () => {
    history.push("/chat");
  };

  const handleRoute = (path) => {
    if (path == "https://mybuilderid.com/faq.html") {
      window.open(path, "_blank");
    } else if (path == "/dashboard") {
      if (pathname.includes("/client")) {
        setViewBuildIdSplashScreen(true);
        setTimeout(() => {
          // setLoading(false);
          setViewBuildIdSplashScreen(false);
          history.push("/dashboard");
        }, 1000);
      } else {
        history.push("/dashboard");
      }
    } else if (path == "/clientChat") {
      alert("Comming Soon...");
    } else if (path == "/clientNotifications") {
      history.push("/client-notifications");
    } else {
      navigate.push(path);
      setOpen(false);
      props?.fetchData();
    }
  };

  const handleRouteOne = (path) => {
    navigate.push(path);
  };

  function openMenu() {
    setOpen(!open);
  }

  function truncateGroupName(group) {
    const maxLength = 15;
    let name = group?.groupUserFirstName1.trim();

    if (group?.nrOfUsersInGroup <= 2) {
      name += ` ${group?.groupUserLastName1?.trim()}`;
    } else if (group?.nrOfUsersInGroup == 3) {
      name += ` and ${group?.groupUserFirstName2.trim()}`;
    } else {
      name += `, ${group?.groupUserFirstName2.trim()} and ${group?.nrOfUsersInGroup - 3
        } others`;
    }

    // Truncate name if it exceeds maxLength
    if (name.length > maxLength) {
      name = name.substring(0, maxLength).trim() + "...";
    }
    return name;
  }

  const navigateToClient = () => {
    const clientList = JSON.parse(localStorage.getItem("clientList"));
    if (clientList?.length > 1) {
      setClientListModalOpen(true);
    } else {
      setClientListModalOpen(false);
      // localStorage.setItem("clientName", clientList[0]?.ClientName);
      localStorage.setItem("OrganizationName", clientList[0]?.OrganizationName);
      localStorage.setItem("ClientLogo", clientList[0]?.ClientLogo);
      localStorage.setItem("clientId", clientList[0]?.ClientId);
      localStorage.setItem("clientEmployeeId", clientList[0]?.ClientEmployeeId);
      localStorage.setItem("clientAccentColor", clientList[0]?.AccentColor);
      history.push(`/client`);
    }
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  }));

  const getAppIcon = (appName) => {
    const clientAccentColor = localStorage?.clientAccentColor;
    switch (appName) {
      case "Expenses":
        return <StickyNote2Icon style={{ color: clientAccentColor }} />;
      case "Timesheet":
        return <CalendarMonthIcon style={{ color: clientAccentColor }} />;
      case "Safety Observations":
        return <SupportIcon style={{ color: clientAccentColor }} />;
      case "Safety Incidents":
        return <AddBoxIcon style={{ color: clientAccentColor }} />;
      case "Coaching":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Picture Board":
        return <ImageIcon style={{ color: clientAccentColor }} />;
      case "Change Tracker":
        return <StickyNote2Icon style={{ color: clientAccentColor }} />;
      case "Communicator":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Take5":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Site Estimates":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Quality Control":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Support":
        return <LiveHelpIcon style={{ color: clientAccentColor }} />;
      case "Site Activity Reports":
        return <PlaceIcon style={{ color: clientAccentColor }} />;
      case "Actions":
        return <ListIcon style={{ color: clientAccentColor }} />;
      case "Site Filing":
        return <ListIcon style={{ color: clientAccentColor }} />;
      default:
        return null;
    }
  };

  const getPath = (appName) => {
    switch (appName) {
      case "Expenses":
        return "/client-expenses";
        break;
      case "Safety Observations":
        return "/client-safety-observations";
        break;
      case "Safety Incidents":
        return "/client-safety-incidents";
        break;
      case "Timesheet":
        return "/client-timesheets";
        break;
      case "Coaching":
        return "/client-coaching";
        break;
      case "Picture Board":
        return "/client-picture-board";
        break;
      case "Change Tracker":
        return "/client-change-tracker";
        break;
      case "Communicator":
        return "/client-communicator";
        break;
      case "Site Estimates":
        return "/client-site-estimation";
        break;
      case "Quality Control":
        return "/client-quality-controls";
        break;
      case "Support":
        return "/client-support";
        break;
      case "Site Activity Reports":
        return "/client-site-activity-reports";
        break;
      case "Actions":
        return "/client-actions";
        break;
      case "Take5":
        return "/client-take-5";
        break;
      case "Site Filing":
        return "/client-site-filing";
        break;
      case "Home":
        return "/client";
        break;
      default:
        return null;
    }
  };


  const navigateTocurrentpage = async (item) => {
    if (item?.appId) {
      localStorage.setItem("ClientAppId", item?.appId)
    }

    if(item?.appName === "BuildID" || item?.path === "/dashboard"){
      // if (pathname.includes("/client")) {
        setViewBuildIdSplashScreen(true);
        setTimeout(() => {
          // setLoading(false);
          setViewBuildIdSplashScreen(false);
          history.push("/dashboard");
        }, 1000);
      // } 
    }
    switch (item?.appName) {
      case "Expenses":
        history.push("/client-expenses");
        break;
      case "Safety Observations":
        history.push("/client-safety-observations");
        break;
      case "Safety Incidents":
        history.push("/client-safety-incidents");
        break;
      case "Timesheet":
        history.push("/client-timesheets");
        break;
      case "Coaching":
        history.push("/client-coaching");
        break;
      case "Picture Board":
        history.push("/client-picture-board");
        break;
      case "Change Tracker":
        history.push("/client-change-tracker");
        break;
      case "Communicator":
        history.push("/client-communicator");
        break;
      case "Site Estimates":
        history.push("/client-site-estimation");
        break;
      case "Quality Control":
        history.push("/client-quality-controls");
        break;
      case "Support":
        history.push("/client-support");
        break;
      case "Site Activity Reports":
        history.push("/client-site-activity-reports");
        break;
      case "Actions":
        history.push("/client-actions");
        break;
      case "Take5":
        history.push("/client-take-5");
        break;
      case "Site Filing":
        history.push("/client-site-filing");
        break;
      case "Home":
        history.push("/client");
        break;
      default:
        return null;
    }
    if (item?.id) {
      const obj = {
        Id: item?.id,
        IsPin: item?.isPin,
      };
      await updateEmployeeLicensesIsPinFlag(obj);
    }
  };

  const getList = async () => {
    // props?.setIsClientApplicationsLoading(true);
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: localStorage.userId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            ClientId: Number(localStorage.clientId),
            EmployeeId: Number(localStorage.clientEmployeeId),
          },
        ],
      };

      const clientApprovedPictureBoardRes = await getClientApprovedPictureBoard(
        Number(localStorage.clientId)
      );
      // setClientApprovedPictureBoardData(clientApprovedPictureBoardRes);

      const res = await fetchClientEmployeeLicenses(obj);
      const modifiedList = res?.data.filter((item) => item?.appName !== "Chat");

      // const isChat = res?.data.some((item) => item.appName == "Chat");
      // props?.setIsShowChatInClient(isChat);

      const finalList = await Promise.all(
        modifiedList.map(async (item) => {
          return {
            ...item,
            selected: item.isPin,
            loading: false,
            icon: getAppIcon(item?.appName),
            path: getPath(item?.appName)
          };
        })
      );

      // Separate pinned and non-pinned items
      const pinnedItems = finalList?.filter((item) => item.isPin);
      const nonPinnedItems = finalList?.filter((item) => !item.isPin);

      // Sort both lists alphabetically by a key (assuming 'appName' or another key for sorting)
      // pinnedItems?.sort((a, b) => a.appName?.localeCompare(b.appName));
      nonPinnedItems?.sort((a, b) => a.appName?.localeCompare(b.appName));

      // Concatenate the sorted lists, with pinned items first
      let sortedFinalList = [...pinnedItems, ...nonPinnedItems];

      // Remove 'pictureboad' item if clientApprovedPictureBoardRes exists and has items
      if (clientApprovedPictureBoardRes?.length > 0) {
        sortedFinalList = sortedFinalList?.filter(
          (item) => item.appName !== "Picture Board"
        );
      }

      // sortedFinalList = sortedFinalList?.filter((item)=>item.appName !== "Support Requests")

      // sortedFinalList = sortedFinalList?.filter((item) => item.appName !== "Take 5");

      sortedFinalList.unshift({
        path: "/client",
        icon: <HomeIcon className="icon-font-size" style={{color:localStorage.clientAccentColor}} />,
        appName: "Home",
      })

      sortedFinalList.push({
        path: '/dashboard',
        icon: <img
          src={logo}
          alt="icon"
          style={{ width: "24px", height: "24px" }}
        />,
        appName: 'BuildID'
      })
      // setClientLicenseData(sortedFinalList);
      // props?.setIsClientApplicationsLoading(false); 
    } catch (error) {
      console.log("ERROR CLIENT APPLICATIONS ", error);
      // props?.setIsClientApplicationsLoading(false);
    }
  };

  function truncateString(str, length) {
    return str.length > length ? str.substring(0, length) + "..." : str;
  }

  useEffect(() => {
    // getList();
  }, [])

  return (
    <>
      {viewBuildIdSplashScreen ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: BIBlue,
            zIndex: 1000,
          }}>
          <img
            sx={{
              width: 50,
              height: 50,
              border: "2px solid white",
            }}
            src={BuildIDLogo}
            style={{ animation: "zoomIn 0.8s ease-out" }}
          />

          {/* Add keyframes for zoom-in effect */}
          <style>
            {`
            @keyframes zoomIn {
            0% {
                transform: scale(0.5);
                opacity: 0;
              }
            100% {
                transform: scale(1);
                opacity: 1;
              }
            }
          `}
          </style>
        </div>
      ) : (
        <></>
      )}

      {pathname.includes("/client") ? (
        <>
          <DrawerHeader
            className={"client-header-main"}
            style={{ background: localStorage.getItem("clientAccentColor") }}
          />
          {
            <div className="drawer-header-menulist">
              {/* <List component="nav" className="navbar" style={{paddingBottom:'0px'}}>
                {clientMenuList.map((item, index) => {
                  if (
                    item.path === "/clientChat" &&
                    !props?.isShowChatInClient
                  ) {
                    return null; // Don't render the Chat menu if isShowChatInClient is false
                  }
                  return (
                    <ListItem
                      key={index}
                      onClick={() => handleRoute(item.path)}
                      className={
                        pathname === item.path
                          ? "navbar-list-item"
                          : "list-item-hower"
                      }
                      style={{ marginTop: index === 0 ? "10px" : "" }}>
                      <ListItemIcon
                        className="list-item-icon-width"
                        style={{
                          color: pathname === item.path ? "#083db8" : "#4f4f4f",
                        }}>
                        {item.icon || (
                          <img
                            src={logo}
                            alt="icon"
                            style={{ width: "24px", height: "24px" }}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title || item?.appName}
                        className={
                          pathname === item.path
                            ? "text-bold menu-font-size"
                            : "menu-font-size"
                        }
                        style={{
                          color: pathname === item.path ? "#083db8" : "#606060",
                          fontWeight:
                            pathname === item.path ? "bold" : "normal",
                        }}
                      />
                    </ListItem>
                  );
                })}
              </List> */}
              {/* <Divider />
              <Stack
                alignItems="center" // Centers content horizontally
                justifyContent="center" // Centers content vertically
                sx={{ marginTop: 1 }} // Full viewport height + top margin
              >
                <Typography variant="p">My Application</Typography>
              </Stack> */}
              <Stack>
                <List component="nav" className="navbar" style={{ paddingTop: '0px' }}>
                  {props?.clientLicenseData.map((item, index) => {
                    return (
                      <Tooltip
                        key={index}
                        title={item.appName}
                        placement="right"
                        arrow
                      >
                        <ListItem
                          key={index}
                          onClick={() => navigateTocurrentpage(item)}
                          className={
                            pathname === item.path
                              ? "navbar-list-item"
                              : "list-item-hower"
                          }
                          style={{ marginTop: index === 0 ? "10px" : "",
                          marginBottom: index !== props?.clientLicenseData.length - 1 ? "8px" : "", // Add gap except for the last item
                          }}>
                          <ListItemIcon
                            className="list-item-icon-width"
                            style={{
                              color: pathname === item.path ? "#083db8" : "#4f4f4f",
                            }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={truncateString(item.appName, 10)}
                            className={
                              pathname === item.path
                                ? "text-bold menu-font-size"
                                : "menu-font-size"
                            }
                            style={{
                              color: pathname === item.path ? "#083db8" : "#606060",
                              fontWeight:
                                pathname === item.path ? "bold" : "normal",
                            }}
                          />
                          {item.isPin && <ListItemIcon
                            className="list-item-icon-width"
                            style={{
                              color: pathname === item.path ? "#083db8" : "#4f4f4f",
                            }}
                          >
                            <PushPinIcon style={{ color: localStorage.clientAccentColor }} />
                          </ListItemIcon>}
                        </ListItem>
                        </Tooltip>
                        );
                  })}
                      </List>
              </Stack>
            </div>
          }
        </>
      ) : (
        <>
          <DrawerHeader className={"drawer-header-main"} />
          <div className="drawer-header-menulist">
            <List component="nav" className="navbar">
              {Menu1.map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    onClick={() => handleRoute(item.path)}
                    className={
                      pathname === item.path
                        ? "navbar-list-item"
                        : "list-item-hower"
                    }>
                    <ListItemIcon
                      className="list-item-icon-width"
                      style={{
                        color: pathname === item.path ? "#083db8" : "#4f4f4f",
                      }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      className={
                        pathname === item.path
                          ? "text-bold menu-font-size"
                          : "menu-font-size"
                      }
                      style={{
                        color: pathname === item.path ? "#083db8" : "#606060",
                      }}
                    />
                  </ListItem>
                );
              })}
              <Divider />
              <p className="nav-paragraph">My Experience</p>
              {Menu2.map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    onClick={() => handleRoute(item.path)}
                    className={
                      pathname === item.path
                        ? "navbar-list-item"
                        : "list-item-hower"
                    }>
                    <ListItemIcon
                      className="list-item-icon-width"
                      style={{
                        color: pathname === item.path ? "#083db8" : "#4f4f4f",
                      }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      className={
                        pathname === item.path
                          ? "text-bold menu-font-size"
                          : "menu-font-size"
                      }
                      style={{
                        color: pathname === item.path ? "#083db8" : "#606060",
                        fontWeight: pathname === item.path ? "bold" : "normal",
                      }}
                    />
                  </ListItem>
                );
              })}
              <Divider />
              <p className="nav-paragraph">Community</p>
              {Menu3.map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    onClick={() => handleRoute(item.path)}
                    className={
                      pathname === item.path
                        ? "navbar-list-item"
                        : "list-item-hower"
                    }>
                    <ListItemIcon
                      className="list-item-icon-width"
                      style={{
                        color: pathname === item.path ? "#083db8" : "#4f4f4f",
                      }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      className={
                        pathname === item.path
                          ? "text-bold menu-font-size"
                          : "menu-font-size"
                      }
                      style={{
                        color: pathname === item.path ? "#083db8" : "#606060",
                        fontWeight: pathname === item.path ? "bold" : "normal",
                      }}
                    />
                  </ListItem>
                );
              })}
              <Divider />
              {/* {JSON.parse(localStorage.getItem("clientList")) &&
                <>
                  <Divider />
                  <p className="nav-paragraph" style={{ cursor: 'pointer' }} onClick={navigateToClient}>Account</p>
                  <Divider />
                </>
              } */}
              {props?.chatGroupData?.length > 0 && (
                <>
                  <p className="nav-paragraph">My Groups</p>
                  {props?.chatGroupData
                    ?.filter((group) => group.nrOfUsersInGroup > 3)
                    ?.slice(0, 3)
                    ?.map((group, index) => (
                      <ListItem
                        className="list-item-hower"
                        key={index}
                        onClick={() => selectCurrentItem(group)}>
                        <ListItemAvatar>
                          <Avatar
                            alt="avatar"
                            src={group?.groupUserPictureUrl1}
                            style={{
                              height: "1.5rem",
                              width: "1.5rem",
                              cursor: "pointer",
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          className="menu-font-size"
                          style={{ color: "#606060" }}>
                          {truncateGroupName(group)}
                        </ListItemText>
                      </ListItem>
                    ))}
                  <Button
                    fullWidth
                    size="small"
                    sx={{ color: "rgb(79, 79, 79)" }}
                    onClick={() => navigateAll()}>
                    See all
                  </Button>
                </>
              )}
              <Divider />
              {Menu4.map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    onClick={() => handleRoute(item.path)}
                    className={
                      pathname === item.path
                        ? "navbar-list-item"
                        : "list-item-hower"
                    }
                    style={{ marginTop: index === 0 ? "10px" : "" }}>
                    <ListItemIcon
                      className="list-item-icon-width"
                      style={{
                        color: pathname === item.path ? "#083db8" : "#4f4f4f",
                      }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      className={
                        pathname === item.path
                          ? "text-bold menu-font-size"
                          : "menu-font-size"
                      }
                      style={{
                        color: pathname === item.path ? "#083db8" : "#606060",
                        fontWeight: pathname === item.path ? "bold" : "normal",
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </>
      )}
      {clientListModalOpen && (
        <ClientListModal
          isOpen={clientListModalOpen}
          onClose={() => setClientListModalOpen(false)}
          setClientName={props?.setClientName}
        />
      )}
    </>
  );
};
export default MenuList;
