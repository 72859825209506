import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Modal,
  Typography,
  Grid,
  TextField,
  FormGroup,
  InputAdornment,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  FormControlLabel,
} from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import {
  GoogleMap,
  Marker,
  Autocomplete as GooglePlacesAutocomplete,
} from "@react-google-maps/api";
import {
  fetchSitesCountry,
  getAddress,
  saveSitesDescriptions,
  saveSitesDetails,
} from "../../api/SitesApi";
import {
  Checkbox,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";

const containerStyle = {
  width: "100%",
  height: "185px",
  border: "1px solid #000000d6",
  marginBottom: "14px",
};

const AddSiteModal = (props) => {
  const history = useHistory();
  const quizLocation = useLocation();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addSite, setAddsite] = useState({
    siteId: 0,
    siteName: "",
    type: "",
    country: "",
    address: "",
    city: "",
    province: "",
    postalCode: "",
    latitude: 0,
    longitude: 0,
    workedSite: false,
    description: "",
    isActive: false,
  });

  useEffect(() => {
    getInitaldata();
  }, [props.chooseLocation]);

  const getInitaldata = async (params) => {
    try {
      if (props.chooseLocation?.lat !== 0 && props.chooseLocation?.lng !== 0) {
        setIsLoading(true);
        let getLatlng = {
          latitude: params?.currentAddress
            ? props?.currentLocation?.lat
            : props?.chooseLocation?.lat,
          longitude: params?.currentAddress
            ? props?.currentLocation?.lng
            : props.chooseLocation?.lng,
        };
        let addressData = await getAddress(getLatlng);
        let addressObj = addressData.objAddress;
        if (addressData?.objAddress != "") {
          setIsAddressSelected(true);
        }
        setAddsite({
          ...addSite,
          address: params?.currentAddress ? "" : addressObj?.address,
          city: addressObj?.city,
          country: addressObj?.country,
          latitude: addressObj?.latitude,
          longitude: addressObj?.longitude,
          province: addressObj?.province,
          postalCode: addressObj?.postalCode,
        });
        setSelectedCountry(
          addressObj?.country?.toLowerCase() === "india"
            ? "India"
            : addressObj?.country?.toLowerCase() === "united states"
            ? "USA"
            : addressObj?.country?.toLowerCase() === "canada"
            ? "Canada"
            : addressObj?.country?.toLowerCase() === "united kingdom" ||
              addressObj?.country?.toLowerCase() === "united kingdom"
            ? "United Kingdom"
            : ""
        );
      }
      await countryObj();
      setIsLoading(false);
    } catch (error) {
      console.log("Error----", error);
      setIsLoading(false);
    }
  };

  const countryObj = async () => {
    let request = {
      PageNr: 1,
      FullSearch: "",
      IncludeRecordNr: false,
      NrOfRecPerPage: 0,
      SearchList: [],
    };
    let countriesResponse = await fetchSitesCountry(request);
    if (process.env.REACT_APP_BASE_URL.includes("staging")) {
      countriesResponse = countriesResponse?.filter((c) => c.name != "India");
    }
    setCountries(countriesResponse);
  };

  const addSitechange = (e) => {
    setAddsite({
      ...addSite,
      [e.target.name]:
        e.target.name === "isActive" ? e.target.checked : e.target.value,
    });
  };

  const handleClose = () => {
    props.onClose();
  };

  const onChangelocation = () => {
    props.setShowchooselocation(true);
    props.setChangeLocation(true);
  };

  const onChangeCurrentlocation = () => {
    props.setShowchooselocation(true);
    props.setChangeLocation(true);
    props.setAddNewSiteLocation(true);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    if (event.target.value === "Canada") {
      setAddsite({
        ...addSite,
        address: "",
        city: "",
        province: "",
        postalCode: "",
        country: event.target.value,
        latitude: 56.130366,
        longitude: -106.346771,
      });
    } else if (event.target.value === "USA") {
      setAddsite({
        ...addSite,
        address: "",
        city: "",
        province: "",
        postalCode: "",
        country: event.target.value,
        latitude: 37.09024,
        longitude: -95.712891,
      });
    } else if (event.target.value === "United Kingdom") {
      setAddsite({
        ...addSite,
        address: "",
        city: "",
        province: "",
        postalCode: "",
        country: event.target.value,
        latitude: 55.378051,
        longitude: -3.435973,
      });
    } else {
      setAddsite({
        ...addSite,
        address: "",
        city: "",
        province: "",
        postalCode: "",
        country: event.target.value,
      });
    }
  };

  const handleSearch = () => {
    let place = autocomplete.getPlace();
    if (place?.address_components) {
      setIsAddressSelected(true);
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setAddsite({
        ...addSite,
        country:
          place?.address_components.find((address) =>
            address.types.includes("country")
          )?.long_name ?? "",
        address: place?.formatted_address,
        city:
          place?.address_components.find((address) =>
            address.types.includes("locality")
          )?.long_name ?? "",
        province:
          place?.address_components.find((address) =>
            address.types.includes("administrative_area_level_1")
          )?.short_name ?? "",
        postalCode:
          place?.address_components.find((address) =>
            address.types.includes("postal_code")
          )?.long_name ?? "",
        latitude: lat,
        longitude: lng,
      });
      setSelectedCountry(
        place?.address_components
          .find((address) => address.types.includes("country"))
          ?.long_name?.toLowerCase() === "india"
          ? "India"
          : place?.address_components
              .find((address) => address.types.includes("country"))
              ?.long_name?.toLowerCase() === "united states"
          ? "USA"
          : place?.address_components
              .find((address) => address.types.includes("country"))
              ?.long_name?.toLowerCase() === "canada"
          ? "Canada"
          : place?.address_components
              .find((address) => address.types.includes("country"))
              ?.long_name?.toLowerCase() === "united kingdom" ||
            place?.address_components
              .find((address) => address.types.includes("country"))
              ?.long_name?.toLowerCase() === "united kingdom"
          ? "United Kingdom"
          : ""
      );
    }
  };

  const setSearchTerm = async (getAddress) => {
    if (getAddress == "") {
      setAddsite({
        ...addSite,
        address: "",
        city: "",
        country: "",
        latitude: 0,
        longitude: 0,
        province: "",
        postalCode: "",
      });
      // await getLocation();
      // await getInitaldata({ currentAddress: true });
    } else {
      setAddsite({
        ...addSite,
        address: getAddress,
      });
      // setCurrentLocation(false);
    }
  };

  const saveSiterequest = async () => {
    try {
      setIsLoading(true);
      if (addSite?.postalCode != "" && addSite?.postalCode.length > 10) {
        alert(
          `Please enter correct ${
            addSite?.country.toLowerCase() == "usa"
              ? "ZIP code"
              : addSite?.country.toLowerCase() == "united kingdom"
              ? "postcode"
              : "postal code"
          }`
        );
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            Name: addSite?.siteName,
            Address: addSite?.address,
            City: addSite?.city,
            Province: addSite?.province,
            PostalCode: addSite?.postalCode,
            Country: addSite?.country,
            Latitude: addSite?.latitude ?? 0,
            Longitude: addSite?.longitude ?? 0,
            StartDate: "",
            EndDate: "",
            Active: addSite?.isActive,
            RecordStatusId: 1,
            ContractorId: 0,
            JobNo: "",
            ConstructionStatusId: 0,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      const result = await saveSitesDetails(obj);
      if (addSite?.description?.length > 0 && addSite?.description != "") {
        const descObj = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              SiteId: result[0].objData.id,
              Description: addSite?.description,
              ModifiedBy: Number(localStorage.userId),
            },
          ],
        };
        await saveSitesDescriptions(descObj);
      }
      setIsLoading(true);
      if (props?.from == "quiz_site") {
        let obj = {
          from: "map",
          question: quizLocation?.state?.questionData,
          siteInfo: result[0].objData,
          for: "site",
        };
        // history.push({
        //   pathname: "/rewards/earn-rewards",
        //   state: { obj },
        // });

        if (props?.fromQuestion == "dashboard-profile") {
          history.push({
            pathname: "/dashboard",
            state: { obj },
          });
        } else {
          history.push({
            pathname: "/rewards/earn-rewards",
            state: { obj },
          });
        }
      } else if (props?.fromSignUpPage == "signUpPage") {
        props?.setSignUpSiteInfo(result[0].objData);
        props?.setAddSiteModalForSignUp(false);
        props.onClose();
      }

      props?.onDataSave();
    } catch (err) {
      console.log("Error while saving the site ::", err);
      setIsLoading(true);
    }
  };

  const loadSitename = () => {
    setAddsite({
      ...addSite,
      siteName: addSite?.address,
    });
  };

  return (
    <>
      <Modal
        open={props?.show}
        className="add-site-modal"
        onClose={handleClose}
      >
        <div className="modal-center">
          <div
            className="modal-card addsite-card"
            style={{
              height:
                (props?.chooseLocation?.lat === 0 || addSite?.latitude == 0) &&
                "auto",
            }}
          >
            <div className="header-section">
              <Typography className="addsite-title" variant="h6" component="h2">
                Add Site
              </Typography>
              <IconButton color="primary" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div
              style={{
                maxHeight: "555px",
                overflowY: "auto",
                paddingTop: "8px",
              }}
              className="experience-form"
            >
              <div className="site-info">
                <Grid container spacing={2}>
                  <Grid item xs={6} lg={12}>
                    <TextField
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Site Name"
                      fullWidth
                      style={{ marginBottom: "14px" }}
                      name="siteName"
                      value={addSite?.siteName}
                      onChange={addSitechange}
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="site-info">
                {addSite?.latitude !== 0 && addSite?.longitude !== 0 && (
                  <>
                    {/* new code sanket 27-03-2024  */}
                    {isAddressSelected && addSite?.address !== "" && (
                      <h5 className="title">
                        Suggested{" "}
                        <span
                          className="suggested-site"
                          onClick={() => loadSitename()}
                        >
                          {addSite?.address}
                        </span>
                      </h5>
                    )}
                    <GoogleMap
                      options={{
                        fullscreenControl: false,
                        zoomControl: false, // Hides the zoom control buttons
                        scrollwheel: false, // Disables zooming with the mouse wheel
                        gestureHandling: "none", // Prevents all gesture-based interactions
                        disableDefaultUI: true,
                        styles: [
                          {
                            featureType: "poi",
                            stylers: [
                              { visibility: "off" }, // Enable POIs
                            ],
                          },
                        ],
                      }}
                      center={{
                        lat: addSite?.latitude,
                        lng: addSite?.longitude,
                      }}
                      zoom={13}
                      mapContainerStyle={containerStyle}
                    >
                      <Marker
                        position={{
                          lat: addSite?.latitude,
                          lng: addSite?.longitude,
                        }}
                        icon={{
                          url: mapMarkerYellow,
                          scaledSize: new window.google.maps.Size(30, 40),
                        }}
                      />
                    </GoogleMap>
                  </>
                )}
                <Button
                  // className="changeloc-btn"
                  style={{ marginBottom: "15px" }}
                  color="primary"
                  variant={
                    (props.chooseLocation?.lat == 0 &&
                      props.chooseLocation?.lng == 0) ||
                    (addSite?.latitude == 0 && addSite?.longitude == 0)
                      ? "contained"
                      : "outlined"
                  }
                  fullWidth
                  onClick={
                    (props.chooseLocation?.lat == 0 &&
                      props.chooseLocation?.lng == 0) ||
                    (addSite?.latitude == 0 && addSite?.longitude == 0)
                      ? onChangeCurrentlocation
                      : onChangelocation
                  }
                >
                  {(props.chooseLocation?.lat == 0 &&
                    props.chooseLocation?.lng == 0) ||
                  (addSite?.latitude == 0 && addSite?.longitude == 0)
                    ? "choose location"
                    : "Change location"}
                </Button>
                {props?.chooseLocation?.lat !== 0 &&
                  props?.chooseLocation?.lng !== 0 &&
                  addSite?.latitude !== 0 &&
                  addSite?.longitude !== 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={6} lg={6}>
                        <Box size="small">
                          <FormControl size="small" fullWidth>
                            <InputLabel size="small">Country</InputLabel>
                            <Select
                              style={{ height: "41px" }}
                              size="small"
                              label="Country"
                              value={selectedCountry}
                              onChange={handleCountryChange}
                            >
                              {countries.map((country) => {
                                return (
                                  <MenuItem
                                    key={country?.id}
                                    value={country?.name}
                                  >
                                    <ListItemText primary={country?.name} />
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <style>
                          {`.pac-container {
                                                 width: 299px !important;
                                                }`}
                        </style>
                        <GooglePlacesAutocomplete
                          onPlaceChanged={handleSearch}
                          onLoad={(newInstance) => {
                            setAutocomplete(newInstance);
                          }}
                          options={{
                            componentRestrictions: {
                              country: ["US", "CA", "GB"],
                            },
                          }}
                        >
                          <TextField
                            variant="outlined"
                            placeholder="Address"
                            fullWidth
                            size="small"
                            style={{ marginBottom: "14px" }}
                            value={addSite?.address}
                            onChange={(event) => {
                              setSearchTerm(event.target.value);
                              setIsAddressSelected(false);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {addSite?.address !== "" && (
                                    <IconButton
                                      onClick={() => setSearchTerm("")}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </GooglePlacesAutocomplete>
                      </Grid>
                    </Grid>
                  )}

                {props?.chooseLocation?.lat !== 0 &&
                  props?.chooseLocation?.lng !== 0 &&
                  addSite?.latitude !== 0 &&
                  addSite?.longitude !== 0 && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={6} lg={6}>
                          <TextField
                            size="small"
                            type="text"
                            variant="outlined"
                            label="City"
                            name="city"
                            value={addSite?.city}
                            fullWidth
                            style={{ marginBottom: "14px" }}
                            onChange={addSitechange}
                          />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          <TextField
                            size="small"
                            type="text"
                            variant="outlined"
                            label="Province"
                            value={addSite?.province}
                            name="province"
                            fullWidth
                            style={{ marginBottom: "14px" }}
                            onChange={addSitechange}
                          />
                        </Grid>
                      </Grid>
                      <TextField
                        size="small"
                        type="text"
                        variant="outlined"
                        label="Postcode"
                        value={addSite?.postalCode}
                        name="postalCode"
                        fullWidth
                        style={{ marginBottom: "14px" }}
                        onChange={addSitechange}
                      />
                    </>
                  )}
                <TextField
                  fullWidth
                  minRows={2}
                  multiline
                  label="Description"
                  type="text"
                  name="description"
                  value={addSite?.description}
                  onChange={addSitechange}
                />
                <FormGroup style={{ marginBottom: "14px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        value={addSite?.isActive}
                        onChange={addSitechange}
                        name="isActive"
                      />
                    }
                    label="Active Site"
                  />
                </FormGroup>
                {isLoading ? (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={true}
                    fullWidth
                    style={{ marginBottom: "14px" }}
                  >
                    Save{" "}
                    <CircularProgress
                      size={22}
                      style={{ marginLeft: "10px" }}
                    />
                  </Button>
                ) : (
                  <Button
                    style={{ marginBottom: "14px" }}
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={
                      props?.chooseLocation?.lat !== 0 &&
                      props?.chooseLocation?.lng !== 0 &&
                      addSite?.latitude !== 0 &&
                      addSite?.longitude !== 0 &&
                      props?.fromSignUpPage !== "signUpPage"
                        ? false
                        : addSite?.siteName?.trim() === ""
                        ? true
                        : false
                    }
                    onClick={saveSiterequest}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddSiteModal;
