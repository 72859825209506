import React, { useEffect, useState, useRef, memo, useCallback } from "react";
import { Button, Grid, IconButton, Modal, TextField, Box, Typography, CircularProgress, Checkbox, Card, Stack, styled, LinearProgress, Icon } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@material-ui/icons";
import "../../../assets/css/ClientHome.css";
import { BIGrey } from "../../../assets/buildidColors";
import Grid2 from "@mui/material/Unstable_Grid2";
import ReactSignatureCanvas from "react-signature-canvas";
import { LoadingButton } from "@mui/lab";
import { set } from "lodash";

export const ClientQualityControlModel = ({ isOpen, onClose, getCoachingList }) => {
  const inputRef = useRef();

  const [showClear, setShowClear] = useState(true);

  const [adImageIndex, setAdImageIndex] = useState(null);
  const signPadRef = useRef(null);
  const [signBlob, setSignBlob] = useState(null);
  const [icon, setIcon] = useState(false);
  const [count, setCount] = useState({
    passCount: 0,
    failCount: 0,
  });

  const [data, setData] = useState([
    {
      title: "Colour correct",
      id: 1,
      tick: false,
      wrong: false,
      na: false,
      images: [],
      text: "",
    },
    {
      title: "Material free of defects",
      id: 2,
      tick: false,
      wrong: false,
      na: false,
      images: [],
      text: "",
    },
    {
      title: "Panel label attached, panel direction grain direction correct",
      id: 3,
      tick: false,
      wrong: false,
      na: false,
      images: [],
      text: "",
    },
    {
      title: "Plus/minus on overall dimensions written on all sides of panel",
      id: 4,
      tick: false,
      wrong: false,
      na: false,
      images: [],
      text: "",
    },
    {
      title: "Finished cut panels on cnc",
      id: 5,
      tick: false,
      wrong: false,
      na: false,
      images: [],
      text: "",
    },
  ]);

  useEffect(() => {
    getTheCount();
  }, [data]);

  const getSignImg = () => {
    const canvas = signPadRef?.current?.getCanvas();
    if (!canvas) return;

    canvas.toBlob(
      (blob) => {
        setSignBlob(blob);
      },
      "image/png",
      1.0 // High-quality output
    );
  };

  const getTheCount = () => {
    let passCount = 0;
    let failCount = 0;
    let totalCount = data?.length;
    data.forEach((item) => {
      if (item.tick) {
        passCount++;
      } else if (item.wrong) {
        failCount++;
      } else if (item.na) {
        totalCount--;
      }
    });
    setCount({ passCount, failCount, totalCount });
  };

  const ClickableText = styled(Typography)(({ theme }) => ({
    "cursor": "pointer",
    "transition": " color 0.1s ease",
    "&:hover": {
      // Change color on hover
    },

    "userSelect": "none", // Prevent text selection
    "&:active": {
      color: "lightgrey",
    },
  }));

  function SignaturePad() {
    return (
      <div style={{ width: "100%", maxWidth: "600px", margin: "0 auto" }}>
        <ReactSignatureCanvas
          onBegin={() => {
            if (!showClear) {
              setShowClear(true);
            }
          }}
          clearOnResize={false}
          ref={signPadRef}
          penColor="black"
          onEnd={() => {
            // setShowClear(true);
          }}
          canvasProps={{
            className: "sigCanvas",
          }}
        />
        <style>
          {`
          .sigCanvas {
            width: 100% !important;
            height: 150px !important;
            border: 1px solid grey; 
            border-radius: 10px;
          }
        `}
        </style>
      </div>
    );
  }

  const handleTextChange = useCallback((id, newText) => {
    setData((prevData) => prevData.map((item) => (item.id === id ? { ...item, text: newText } : item)));
  }, []);

  const ProgressBar = ({ passCount, failCount, total }) => {
    const passPercentage = (passCount / total) * 100;
    const failPercentage = (failCount / total) * 100;

    return (
      <Box sx={{ width: "100%", mt: 2 }}>
        {/* Labels */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Box display={"flex"} gap={1}>
            <Typography variant="body2" sx={{ color: "green", fontWeight: "bold" }}>
              {passCount} Pass
            </Typography>
            <Typography variant="body2" sx={{ color: "red", fontWeight: "bold" }}>
              {failCount} Fail
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {passCount + failCount}/{total} done
          </Typography>
        </Box>

        {/* Progress Bar */}
        <Box
          sx={{
            display: "flex",
            height: 10,
            backgroundColor: "#e0e0e0",
            borderRadius: 5,
            overflow: "hidden",
          }}
        >
          {/* Green progress */}
          <Box
            sx={{
              width: `${passPercentage}%`,
              backgroundColor: "green",
            }}
          ></Box>
          {/* Red progress */}
          <Box
            sx={{
              width: `${failPercentage}%`,
              backgroundColor: "red",
            }}
          ></Box>
        </Box>
      </Box>
    );
  };

  const handelImageChange = (e) => {
    const files = e.target.files;
    const newImages = [...data[adImageIndex].images];
    for (let i = 0; i < files.length; i++) {
      newImages.push(files[i]);
    }
    setData((prev) =>
      prev.map((item, index) => {
        if (index === adImageIndex) {
          return { ...item, images: newImages };
        }
        return item;
      })
    );
  };

  const handelImageRemove = useCallback((mainIndex, index1) => {
    console.log("mainIndex", mainIndex, "index", index1);
    setData((prev) =>
      prev.map((item, i) => {
        if (i === mainIndex) {
          return { ...item, images: item.images.filter((img, imgIndex) => imgIndex !== index1) };
        }
        return item;
      })
    );
  }, []);

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <div className="modal-center">
          <div
            className="modal-card"
            style={{
              maxHeight: "600px",
              width: "600px",
              padding: "10px",
              backgroundColor: "#f2f2f2",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "white",
                padding: "5px",
                borderRadius: "5px 5px 0px 0px",
                marginRight: "4px",
              }}
            >
              <Typography variant="body2" fontWeight={"bold"} color={BIGrey}>
                AP300 Panels - CNC
              </Typography>
              <IconButton color="primary" onClick={onClose} style={{ padding: "0px" }}>
                <Close />
              </IconButton>
            </div>

            <Grid2 size={12} fullWidth maxHeight={"500px"} overflow={"auto"}>
              {data.map((item, index) => (
                <Grid2 mb={1} item size={12}>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      padding: "0px",
                      backgroundColor: item?.na ? "transparent" : "white",
                    }}
                  >
                    <Stack display={"flex"} alignItems={"center"} flexDirection={"row"} justifyContent={"space-between"} p={2}>
                      <Typography variant="subtitle1" fontWeight={"bold"} color={BIGrey}>
                        {item?.na ? <strike>{item?.title}</strike> : item?.title}
                      </Typography>
                      <Stack display={"flex"} ml={1} flexDirection={"row"} justifyContent={"space-between"} gap={1}>
                        <IconButton
                          onClick={() => setData((prev) => prev.map((item, i) => (i === index ? { ...item, tick: !item.tick } : item)))}
                          sx={{
                            ":hover": {
                              backgroundColor: item.tick ? "#c3e6ba" : "#f2f2f2",
                            },
                            "backgroundColor": item.tick ? "#c3e6ba" : "#f2f2f2",
                          }}
                          size="small"
                        >
                          <DoneIcon
                            sx={{
                              color: item.tick ? "green" : "",
                            }}
                          />
                        </IconButton>

                        <IconButton
                          onClick={() => setData((prev) => prev.map((item, i) => (i === index ? { ...item, wrong: !item.wrong } : item)))}
                          sx={{
                            ":hover": {
                              backgroundColor: item.wrong ? "#fcbdbd" : "#f2f2f2",
                            },
                            "backgroundColor": item.wrong ? "#fcbdbd" : "#f2f2f2",
                          }}
                          size="small"
                        >
                          <CloseIcon
                            sx={{
                              color: item.wrong ? "#ff0000" : "",
                            }}
                          />
                        </IconButton>

                        <IconButton
                          onClick={() => setData((prev) => prev.map((item, i) => (i === index ? { ...item, na: !item.na } : item)))}
                          sx={{
                            ":hover": {
                              backgroundColor: item.na ? "#a4a4a4" : "#f2f2f2",
                            },
                            "backgroundColor": item.na ? "#a4a4a4" : "#f2f2f2",
                          }}
                          size="small"
                        >
                          <Typography
                            sx={{
                              p: "1px",
                              m: 0,
                            }}
                            fontSize={12}
                          >
                            N/A
                          </Typography>
                        </IconButton>
                      </Stack>
                    </Stack>
                    <Grid2 p={2} pt={0} xl={12} lg={12} md={12} sm={12} xs={12} container>
                      {item?.tick && (
                        <>
                          <Grid2 pt={0} mb={1} xl={12} lg={12} md={12} sm={12} xs={12} item>
                            <Stack display={"flex"} flexDirection={"row"} gap={1} overflow={"auto"}>
                              <ImageMemoWrapped mainIndex={index} file={item?.images} handelImageRemove={handelImageRemove} />
                            </Stack>
                          </Grid2>
                          <Grid2 pt={0} mb={1} xl={12} lg={12} md={12} sm={12} xs={12} item>
                            <ClickableText
                              onClick={() => {
                                inputRef.current.click();
                                setAdImageIndex(index);
                              }}
                              style={{
                                color: localStorage.clientAccentColor,
                                fontSize: "12px",
                                fontWeight: "bold",
                                userSelect: "none",
                              }}
                            >
                              Add Picture
                            </ClickableText>
                          </Grid2>
                          {!item?.wrong && (
                            <Grid2 pt={0} xl={12} lg={12} md={12} sm={12} xs={12} item>
                              <TextField
                                onChange={(e) => handleTextChange(item.id, e.target.value)}
                                multiline
                                fullWidth
                                rows={4}
                                sx={{
                                  "borderRadius": "10px",
                                  "& fieldset": { border: "none" },
                                  "border": "1px solid #a4a4a4",
                                }}
                                placeholder="Write something (required)"
                              />
                            </Grid2>
                          )}
                        </>
                      )}
                    </Grid2>
                  </Card>
                </Grid2>
              ))}
              <Grid2 p={1} borderRadius={2} backgroundColor={"white"} direction={"column"} xl={12} lg={12} md={12} sm={12} xs={12} item>
                <Grid2
                  size={{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                  }}
                  item
                >
                  <ProgressBar passCount={count.passCount} failCount={count.failCount} total={count?.totalCount} />
                </Grid2>
                <Grid2
                  sx={{
                    mb: 2,
                    mt: 2,
                  }}
                  size={{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                  }}
                  item
                >
                  <Typography variant="subtitle1" color={BIGrey}>
                    All items must be completed and signed off before submission.
                  </Typography>
                </Grid2>
                <Grid2 pt={0} xl={12} lg={12} md={12} sm={12} xs={12} item>
                  <Stack>
                    <Typography mb={1}>Signature</Typography>
                    <Box
                      sx={{
                        position: "relative",
                      }}
                    >
                      <SignaturePad />
                      {showClear && (
                        <ClickableText
                          onClick={() => {
                            signPadRef.current.clear();
                            setShowClear(false);
                          }}
                          variant="p"
                          size="small"
                          sx={{
                            position: "absolute",
                            top: 2,
                            right: 7,
                            zIndex: 100,
                            color: localStorage.clientAccentColor,
                          }}
                        >
                          Clear
                        </ClickableText>
                      )}
                    </Box>
                  </Stack>
                  <LoadingButton
                    style={{
                      borderRadius: "10px",
                      marginTop: "10px",
                      backgroundColor: localStorage.clientAccentColor,
                      color: "white",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      getSignImg();
                    }}
                    fullWidth
                  >
                    Submit
                  </LoadingButton>
                </Grid2>
                {signBlob && <ImageComponent file={[signBlob]} />}
              </Grid2>
            </Grid2>
          </div>
          <input
            onChange={handelImageChange}
            type="file"
            id="file"
            ref={inputRef}
            style={{ display: "none" }}
            multiple
            accept="
                image/png, image/jpeg, image/jpg"
          />
        </div>
      </Modal>
    </>
  );
};

const ImageComponent = (props) => {
  console.log("propsakhsgdfkhasghdfhg", props);
  const { file, handelImageRemove, mainIndex } = props;
  const handelRemove = (mainIndex, index) => {
    handelImageRemove(mainIndex, index);
  };
  const imgUrl = (img) => {
    try {
      return URL.createObjectURL(img);
    } catch (error) {
      return img;
    }
  };
  return (
    <>
      {file?.map((item, index) => {
        return (
          <div
            style={{
              position: "relative",
            }}
          >
            <img
              style={{
                minWidth: "100px",
                maxHeight: "100px",
                borderRadius: "10px",
              }}
              loading="lazy"
              src={imgUrl(item)}
              alt=""
              srcset=""
            />
            <IconButton
              size="small"
              sx={{
                height: 10,
                width: 20,
                backgroundColor: "white",
                position: "absolute",
                top: 0,
                right: 0,
              }}
              onClick={() => handelRemove(mainIndex, index)}
            >
              <CloseIcon
                sx={{
                  color: "red",
                }}
              />
            </IconButton>
          </div>
        );
      })}
    </>
  );
};

const ImageMemoWrapped = memo(ImageComponent);
