import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import moment from "moment";
import {
  BIBlack,
  BIBrown,
  BIGreen,
  BIGrey,
  BILightGreen,
  BILightGrey,
  BILightOrange,
  BILightRed,
  BILightYellow,
  BIRed,
} from "../../../assets/buildidColors";
import PersonIcon from '@mui/icons-material/Person';

export const ClientActionsList = ({ actionItem, activeTab }) => {
  console.log("actionItem", actionItem);
  // console.log("activeTab", activeTab);
  const {
    userProfilePicture,
    employeeName,
    dueDate,
    title,
    description,
    status,
  } = actionItem;
  return (
    <>
      <Grid
        style={{ cursor: "pointer" }}
        marginBottom={1}
        container
        alignContent={"center"}
        md={12}
        lg={12}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <Grid
              gap={1}
              container
              justifyContent="space-between"
              alignItems={"center"}
              paddingInline={2}
              paddingTop={2}
            >
              <Grid item>
                {
                  activeTab === 'Submitted' ?(
                    <PersonIcon style={{ color : localStorage?.clientAccentColor, }} />
                    // <Avatar alt={userProfilePicture} src={userProfilePicture} />
                  ):(
                  <Typography variant="p" color={BIGrey}>
                    {`Due ${moment(dueDate).format("MMMM D, YYYY")}`}
                  </Typography>
                  )
                }
              </Grid>
              {
                activeTab === 'Submitted' &&(
                  <Grid item>
                    <Typography variant="p">{employeeName}</Typography>
                  </Grid>
                )
              }
              <Grid item sx={{ marginLeft: "auto" }}>
              {
                activeTab === 'Submitted' &&(
                  <Typography variant="p" color={BIGrey}>
                    {`Due ${moment(dueDate).format("MMMM D, YYYY")}`}
                  </Typography>
                )
              }
                
              </Grid>
            </Grid>
            <CardContent>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography variant="body1">{title}</Typography>
                </Grid>
              </Grid>
              <Typography
                variant="body2"
                style={{ marginTop: "10px" }}
                color="textSecondary"
              >
                {description}
              </Typography>
              <div style={{ width: "fit-content", marginTop: "2px" }}>
               
                <Chip
                  label={status}
                  style={
                    status === "In Progress"
                      ? {
                        backgroundColor: BILightYellow,
                        color: "brown",
                      }
                      : status === "Overdue"
                        ? { backgroundColor: BILightRed, color: BIRed }
                        : status === "Not Started"
                          ? {
                            backgroundColor: BILightGrey,
                            color: BIGrey,
                          }
                          : status === "On Hold"
                            ? {
                              backgroundColor: "pink",
                              color: "brown",
                            }
                            : {
                              backgroundColor: BILightGreen,
                              color: BIGreen,
                            }
                  }
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};