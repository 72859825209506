import { Modal, Typography } from "@mui/material";
import React from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@material-ui/core";

const FabOptions = (props) => {
  const fileInputRef = useRef(null);

  const handleClose = () => {
    props?.onClose();
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    let allFilesValid = true;
    if (files?.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (!files[i].type.startsWith("image/")) {
          allFilesValid = false;
          break;
        }
      }
    }
    if (!allFilesValid)
      return alert("Please select valid image files (JPEG, PNG, GIF, etc.).");
    let images = [];
    for (let i = 0; i < files.length; i++) {
      images.push(files[i]);
    }
    event.target.value = null;
    if (images.length > 0) {
      props?.onImageSelected(images);
    }
  };

  const openAddsite = async (flag) => {
    props.onClose();
    if (flag === "addsite") {
      props.setChooselocation({
        lat: 0,
        lng: 0,
      });
    } else {
      props?.setChooselocation({
        lat: props?.currentLocation?.lat,
        lng: props?.currentLocation?.lng,
      });
    }
    if (
      props?.from === "experience" ||
      props?.from === "companyProfile" ||
      props?.from === "quiz"
    ) {
      props?.onAddProjectForProfile();
    } else {
      props.setShowAddSiteModal(true);
    }
  };

  return (
    <>
      <Modal className="choose-opt" open={props.open} onClose={handleClose}>
        <div className="modal-center">
          <div className="modal-card chooseopt-card">
            <div className="header-section">
              <Typography
                className="chooseopt-title"
                variant="h6"
                component="h2"
              >
                Select Option
              </Typography>
              <IconButton color="primary" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="options">
              {props?.from === "experience" ||
              props?.from === "companyProfile" ||
              props?.from === "quiz" ? null : (
                <div
                  className="select-loc"
                  onClick={() => openAddsite("addsite")}
                >
                  <IconButton className="insert-btn">
                    <AddIcon className="opt-icon" />
                  </IconButton>
                  <span className="select-text" style={{ border: "none" }}>
                    Add Site
                  </span>
                </div>
              )}
              <div
                className="select-loc"
                onClick={() => openAddsite("mylocation")}
              >
                <IconButton className="insert-btn">
                  <LocationOnIcon className="opt-icon" />
                </IconButton>
                <span className="select-text" style={{ border: "none" }}>
                  Pin my location
                </span>
              </div>
              {props?.fromSignUpPage == "signUpPage" ? (
                <></>
              ) : (
                <div
                  className="select-loc"
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  <IconButton className="insert-btn">
                    <InsertPhotoIcon className="opt-icon" />
                  </IconButton>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                    accept="image/*"
                    multiple
                  />
                  <span className="select-text" style={{ border: "none" }}>
                    Select Image
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FabOptions;
