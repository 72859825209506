import React, { useEffect, useState } from "react";
import HomeModel from "./HomeModel";
import "../../assets/css/ClientHome.css";
import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
  Stack,
  Modal,
  IconButton,
  CircularProgress,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useHistory } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import AddBoxIcon from "@mui/icons-material/AddBox";
// import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
// import EngineeringIcon from "@mui/icons-material/Engineering";
import SupportIcon from "@mui/icons-material/Support";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import ChatIcon from "@mui/icons-material/Chat";
import PlaceIcon from "@mui/icons-material/Place";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { BIGrey } from "../../assets/buildidColors";
import Addexpenses from "../client/clientExpenses/Addexpenses";
import { ClientHeader } from "./ClientHeader";
import {
  fetchClientEmployeeLicenses,
  getClientAppListCountDetails,
  getClientApprovedPictureBoard,
  updateEmployeeLicensesIsPinFlag,
} from "../../api/ClientApi";
import { Chip } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import PushPinIcon from "@mui/icons-material/PushPin";
import { Close } from "@material-ui/icons";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import moment from "moment";
import PictureViewer from "../../components/PictureViewer";
import ListIcon from "@mui/icons-material/List";
import Loader from "../../components/Loader";
import logo from "../../assets/images/logo.png"

export default function ClientHome(props) {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [clientLicenseData, setClientLicenseData] = useState([]);
  const [clientApprovedPictureBoardData, setClientApprovedPictureBoardData] =
    useState([]);
  const [openEditLicenseListModal, setOpenEditLicenseListModal] =
    useState(false);
  const [sortBy, setSortBy] = useState("alphabetical");
  const [viewPictureViewerOpen, setViewPictureViewerOpen] = useState(false);
  const history = useHistory(); // Initialize useHistory for navigation
  const clientAccentColor = localStorage.getItem("clientAccentColor");
  const [pendingRecordsCount, setPendingRecordsCount] = useState({});

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const navigateTocurrentpage = async (item) => {
    localStorage.setItem("ClientAppId", item?.appId)
    switch (item?.appName) {
      case "Expenses":
        history.push("/client-expenses");
        break;
      case "Safety Observations":
        history.push("/client-safety-observations");
        break;
      case "Safety Incidents":
        history.push("/client-safety-incidents");
        break;
      case "Timesheet":
        history.push("/client-timesheets");
        break;
      case "Coaching":
        history.push("/client-coaching");
        break;
      case "Picture Board":
        history.push("/client-picture-board");
        break;
      case "Change Tracker":
        history.push("/client-change-tracker");
        break;
      case "Communicator":
        history.push("/client-communicator");
        break;
      case "Site Estimates":
        history.push("/client-site-estimation");
        break;
      case "Quality Control":
        history.push("/client-quality-controls");
        break;
      case "Support":
        history.push("/client-support");
        break;
      case "Site Activity Reports":
        history.push("/client-site-activity-reports");
        break;
      case "Actions":
        history.push("/client-actions");
        break;
      case "Take5":
        history.push("/client-take-5");
        break;
      case "Site Filing":
        history.push("/client-site-filing");
        break;
      default:
        return null;
    }
    if (item?.id) {
      const obj = {
        Id: item?.id,
        IsPin: item?.isPin,
      };
      await updateEmployeeLicensesIsPinFlag(obj);
    }
  };

  const getAppIcon = (appName) => {
    switch (appName) {
      case "Expenses":
        return <StickyNote2Icon style={{ color: clientAccentColor }} />;
      case "Timesheet":
        return <CalendarMonthIcon style={{ color: clientAccentColor }} />;
      case "Safety Observations":
        return <SupportIcon style={{ color: clientAccentColor }} />;
      case "Safety Incidents":
        return <AddBoxIcon style={{ color: clientAccentColor }} />;
      case "Coaching":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Picture Board":
        return <ImageIcon style={{ color: clientAccentColor }} />;
      case "Change Tracker":
        return <StickyNote2Icon style={{ color: clientAccentColor }} />;
      case "Communicator":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Take5":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Site Estimates":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Quality Control":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Support":
        return <LiveHelpIcon style={{ color: clientAccentColor }} />;
      case "Site Activity Reports":
        return <PlaceIcon style={{ color: clientAccentColor }} />;
      case "Actions":
        return <ListIcon style={{ color: clientAccentColor }} />;
      case "Site Filing":
        return <ListIcon style={{ color: clientAccentColor }} />;
      default:
        return null;
    }
  };

  const handleSortByValue = () => {
    if (sortBy === "alphabetical") {
      setSortBy("recently used");
      const pinnedItems = props?.clientLicenseData?.filter((item) => item.isPin);
      const nonPinnedItems = props?.clientLicenseData?.filter((item) => !item.isPin);
      const sortedByRecentUsedDate = [...nonPinnedItems].sort((a, b) => {
        if (!a.recentUsedAppDate && !b.recentUsedAppDate) return 0;
        if (!a.recentUsedAppDate) return 1;
        if (!b.recentUsedAppDate) return -1;
        return new Date(b.recentUsedAppDate) - new Date(a.recentUsedAppDate);
      });
      props?.setClientLicenseData([...pinnedItems, ...sortedByRecentUsedDate]);
    } else {
      setSortBy("alphabetical");
      getList();
    }
  };

  const getList = async () => {
    // props?.setIsClientApplicationsLoading(true);
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: localStorage.userId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            ClientId: Number(localStorage.clientId),
            EmployeeId: Number(localStorage.clientEmployeeId),
          },
        ],
      };

      const clientApprovedPictureBoardRes = await getClientApprovedPictureBoard(
        Number(localStorage.clientId)
      );
      setClientApprovedPictureBoardData(clientApprovedPictureBoardRes);

      const res = await fetchClientEmployeeLicenses(obj);
      const modifiedList = res?.data.filter((item) => item?.appName !== "Chat");

      const isChat = res?.data.some((item) => item.appName == "Chat");
      props?.setIsShowChatInClient(isChat);

      const finalList = await Promise.all(
        modifiedList.map(async (item) => {
          return {
            ...item,
            selected: item.isPin,
            loading: false,
            icon: getAppIcon(item?.appName),
          };
        })
      );
      // Separate pinned and non-pinned items
      const pinnedItems = finalList?.filter((item) => item.isPin);
      const nonPinnedItems = finalList?.filter((item) => !item.isPin);

      // Sort both lists alphabetically by a key (assuming 'appName' or another key for sorting)
      // pinnedItems?.sort((a, b) => a.appName?.localeCompare(b.appName));
      nonPinnedItems?.sort((a, b) => a.appName?.localeCompare(b.appName));

      // Concatenate the sorted lists, with pinned items first
      let sortedFinalList = [...pinnedItems, ...nonPinnedItems];

      // Remove 'pictureboad' item if clientApprovedPictureBoardRes exists and has items
      if (clientApprovedPictureBoardRes?.length > 0) {
        sortedFinalList = sortedFinalList?.filter(
          (item) => item.appName !== "Picture Board"
        );
      }

      // sortedFinalList = sortedFinalList?.filter((item) => item.appName !== "Take 5");

      sortedFinalList.unshift({
        path: "/client",
        icon: <HomeIcon className="icon-font-size" style={{ color: localStorage.clientAccentColor }} />,
        appName: "Home",
      })

      sortedFinalList.push({
        path: '/dashboard',
        icon: <img
          src={logo}
          alt="icon"
          style={{ width: "24px", height: "24px" }}
        />,
        appName: 'BuildID'
      })

      props?.setClientLicenseData(sortedFinalList);
      props?.setIsClientApplicationsLoading(false);
    } catch (error) {
      console.log("ERROR CLIENT APPLICATIONS ", error);
      // props?.setIsClientApplicationsLoading(false);
    }
  };

  const getPendingRecordsList = async () => {
    const res = await getClientAppListCountDetails(localStorage?.clientId, localStorage?.clientEmployeeId);
    setPendingRecordsCount(res[0]);
  }

  function withoutHomeAndBid() {
    const myApplicationList = props?.clientLicenseData?.filter((item) => item?.appName !== "Home" && item?.appName !== "BuildID")
    if (myApplicationList?.length > 0)
      return true
    else
      return false
  }

  useEffect(() => {
    const fetchData = async () => {
      props?.setIsClientApplicationsLoading(true);
      await getList();
      await getPendingRecordsList();
      props?.setIsClientApplicationsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <ClientHeader screenName={localStorage.getItem("OrganizationName")} isShowChatInClient={props?.isShowChatInClient} />
      {props?.isClientApplicationsLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            background: 'rgb(242, 242, 242)'
          }}>
          <Loader />
        </div>
      ) : withoutHomeAndBid() ? (
        <section
          style={{
            height: withoutHomeAndBid() ? "auto" : "100vh",
          }}
          className="client-main client-home-height">
          {clientApprovedPictureBoardData?.length > 0 && (
            <div className="pictureboard-card">
              <Grid container spacing={3} justifyContent="center">
                {/* First 6-column with one card */}
                <Grid item xs={12} sm={6} md={6} className="first-grid">
                  <Card className="board-card">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigateTocurrentpage({
                          appName: "Picture Board",
                          id: 2,
                          isPin: false,
                          appId: 1,
                        })
                      }
                    >
                      <h4 className="card-title">{"Picture Board"}</h4>
                      <IconButton className="card-title">
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </div>
                    <CardMedia
                      component="img"
                      height="203"
                      image={clientApprovedPictureBoardData[0]?.fileUrl}
                      alt={clientApprovedPictureBoardData[0]?.pictureDescription}
                      onClick={() => setViewPictureViewerOpen(true)}
                    />
                    <CardContent className="board-content">
                      <div className="board-footer">
                        <div className="profile-detail">
                          <Avatar
                            src={clientApprovedPictureBoardData[0]?.userProfilePicture}
                            aria-label="recipe"
                          />
                          <h4 className="profile-name">
                            {clientApprovedPictureBoardData[0]?.userName}
                          </h4>
                        </div>
                        <span>
                          {moment(clientApprovedPictureBoardData[0]?.uploadDate).format(
                            "MMMM D, YYYY"
                          )}
                        </span>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Second 6-column with 4 centered cards */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="stretch" // Ensures cards inside the second grid stretch to match the height of the first grid
                  style={{ height: "100%" }} // Ensures the second grid takes the full height
                >
                  {/* First Card */}
                  <Grid item xs={12} sm={6}>
                    <Card
                      className="board-card"
                      style={{
                        textAlign: "center",
                        height: "100%", // Ensures consistent height
                        cursor: 'pointer'
                      }}
                      onClick={() => history.push("/client-expenses")}
                    >
                      <CardContent>
                        <Typography variant="h6" component="div">
                          Expenses
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          color="primary"
                          style={{ marginTop: "5px" }}
                        >
                          {pendingRecordsCount?.expensesCount}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                        // style={{ marginTop: "10px" }}
                        >
                          Pending Approval
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Second Card */}
                  <Grid item xs={12} sm={6}>
                    <Card
                      className="board-card"
                      style={{
                        textAlign: "center",
                        height: "100%", // Ensures consistent height
                        cursor: 'pointer'
                      }}
                      onClick={() => history.push("/client-timesheets")}
                    >
                      <CardContent>
                        <Typography variant="h6" component="div">
                          Timesheets
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          color="primary"
                          style={{ marginTop: "5px" }}
                        >
                          {pendingRecordsCount?.timesheetsCount}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                        // style={{ marginTop: "10px" }}
                        >
                          Pending Approval
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Third Card */}
                  <Grid item xs={12} sm={6}>
                    <Card
                      className="board-card"
                      style={{
                        textAlign: "center",
                        height: "100%", // Ensures consistent height
                        cursor: 'pointer'
                      }}
                      onClick={() => history.push("/client-picture-board")}
                    >
                      <CardContent>
                        <Typography variant="h6" component="div">
                          Pictureboard
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          color="primary"
                          style={{ marginTop: "5px" }}
                        >
                          {pendingRecordsCount?.picturesBoardCount}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                        // style={{ marginTop: "10px" }}
                        >
                          Pending Approval
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Fourth Card */}
                  <Grid item xs={12} sm={6}>
                    {/* <Card
                      className="board-card"
                      style={{
                        textAlign: "center",
                        height: "100%", // Ensures consistent height
                      }}
                    >
                      <CardContent>
                        <Typography variant="h6" component="div">
                          Expenses
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          color="primary"
                          style={{ marginTop: "5px" }}
                        >
                          15
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                        // style={{ marginTop: "10px" }}
                        >
                          Pending Approval
                        </Typography>
                      </CardContent>
                    </Card> */}
                  </Grid>
                </Grid>
              </Grid>

            </div>
          )}
          <Grid container spacing={2} className="client-content">
            {/* <Grid item xs={12} md={4} style={{ paddingLeft: "0px" }}>
            <Box
              sx={{
                p: 2,
                borderRadius: "8px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Typography variant="h6" className="link-title">
                  Quick Links
                </Typography>
              </Box>
              <List>
                <Card sx={{ marginBottom: "5px" }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <AttachMoneyIcon style={{ color: BIRed }} />
                    </ListItemIcon>
                    <ListItemText primary={<h4>Add a new Expense</h4>} />
                  </ListItemButton>
                </Card>
              </List>
              <List>
                <Card sx={{ marginBottom: "5px" }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <InsertPhotoIcon style={{ color: BIRed }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={<h4>Submit a picture to the Picture Board</h4>}
                    />
                  </ListItemButton>
                </Card>
              </List>
            </Box>
          </Grid> */}
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  padding: "16px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  borderRadius: "8px",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Left side content */}
                  <Typography variant="h6" className="my-application">
                    My Application
                  </Typography>

                  {/* Right side content */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1, // Adds spacing between "Sort by" and "test"
                    }}
                  >
                    {/* <Typography
                      variant="outlined"
                      className="recent-text"
                      size="small"
                      color={BIGrey}
                      style={{
                        userSelect: "none",
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                      }}
                    >
                      Sort by
                      <span
                        onClick={handleSortByValue}
                        style={{
                          cursor: "pointer",
                          color: localStorage.getItem("clientAccentColor"),
                          userSelect: "none",
                          WebkitUserSelect: "none",
                          MozUserSelect: "none",
                          msUserSelect: "none",
                        }}
                      >
                        {` ${sortBy}`}
                      </span>
                    </Typography> */}
                    {/* <Chip
                      icon={<EditIcon fontSize="small" />}
                      style={{ cursor: "pointer", border: '2px solid ' + localStorage?.clientAccentColor }}
                      // label="Edit"
                      onClick={() => setOpenEditLicenseListModal(true)}
                    /> */}
                    <IconButton style={{ cursor: "pointer", color: localStorage?.clientAccentColor }} onClick={() => setOpenEditLicenseListModal(true)}>
                      <EditIcon size="small" />
                    </IconButton>
                  </Box>
                </Box>

                {props?.clientLicenseData?.filter((item) => item?.isPin)?.map((item) => {
                  return (
                    <List onClick={() => navigateTocurrentpage(item)}>
                      <Card
                        sx={{
                          marginBottom: "5px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <ListItemButton>
                          <ListItemIcon>{item?.icon}</ListItemIcon>
                          <ListItemText primary={<h4>{item?.appName}</h4>} />

                          {item.isPin ? (
                            <ListItemIcon
                              sx={
                                item?.badgeCount > 0
                                  ? { marginRight: "31px" }
                                  : { marginLeft: "50px" }
                              }>
                              {" "}
                              {/* Add ListItemIcon for the pushpin */}
                              {item?.badgeCount > 0 && (
                                <ListItemIcon>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "white",
                                      fontWeight: "bold",
                                      background: "red",
                                      borderRadius: "50%", // Makes the shape round
                                      padding: "0px",
                                      // padding: item?.badgeCount.toString().length === 1 ? '4px' : '4px 8px',   // Padding to make the number fit nicely inside the circle
                                      minWidth:
                                        item?.badgeCount.toString().length === 1
                                          ? "24px"
                                          : "25px", // Ensures a minimum width for the circle
                                      minHeight: "24px", // Ensures a minimum height for the circle
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: "auto",
                                    }}>
                                    {item?.badgeCount}
                                  </Typography>
                                </ListItemIcon>
                              )}
                              <PushPinIcon
                                style={{
                                  color:
                                    localStorage.getItem("clientAccentColor"),
                                }}
                              />
                            </ListItemIcon>
                          ) : item?.badgeCount > 0 ? (
                            <ListItemIcon sx={{ marginLeft: "auto" }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "white",
                                  fontWeight: "bold",
                                  background: "red",
                                  borderRadius: "50%", // Makes the shape round
                                  padding: "0px",
                                  // padding: item?.badgeCount.toString().length === 1 ? '4px' : '4px 8px',   // Padding to make the number fit nicely inside the circle
                                  minWidth:
                                    item?.badgeCount.toString().length === 1
                                      ? "24px"
                                      : "25px", // Ensures a minimum width for the circle
                                  minHeight: "24px", // Ensures a minimum height for the circle
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}>
                                {item?.badgeCount}
                              </Typography>
                            </ListItemIcon>
                          ) : (
                            <></>
                          )}
                        </ListItemButton>
                      </Card>
                    </List>
                  );
                })}
                {/* <Stack direction="row" justifyContent={"center"}>
                  <Chip
                    icon={<EditIcon fontSize="small" />}
                    style={{ cursor: "pointer" }}
                    label="Edit"
                    onClick={() => setOpenEditLicenseListModal(true)}
                  />
                </Stack> */}
              </Box>
            </Grid>
          </Grid>
        </section>
      ) : <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Adjust height as per requirement
          backgroundColor: 'rgb(242, 242, 242)',
        }}
      >
        <Typography variant="h5">No Licenses Found</Typography>
      </Box>}
      {viewPictureViewerOpen && (
        <PictureViewer
          isOpen={viewPictureViewerOpen}
          onClose={() => {
            setViewPictureViewerOpen(false);
          }}
          pictures={clientApprovedPictureBoardData?.map((pic) => ({
            src: pic?.fileUrl || "",
            alt: pic?.pictureDescription || "",
          }))}
          viewerIndex={0}
        />
      )}
      {open && (
        <HomeModel
          open={open}
          handleClose={handleClose}
          selectedItem={selectedItem}
        />
      )}
      {openEditLicenseListModal && (
        <EditClientLicenseListModal
          open={openEditLicenseListModal}
          handleClose={() => {
            setOpenEditLicenseListModal(false);
            getList();
          }}
          clientLicenseData={props?.clientLicenseData}
          setClientLicenseData={props?.setClientLicenseData}
          getList={getList}
        />
      )}
      <Addexpenses />
    </>
  );
}

function EditClientLicenseListModal(props) {
  const withoutHomeAndBuildIdOp = props?.clientLicenseData.filter((item) => item?.appName !== "Home" && item?.appName !== "BuildID")
  const handleIconClick = async (item, index) => {
    try {
      let updatedData = withoutHomeAndBuildIdOp.map((data, i) => {
        if (i === index) {
          return { ...data, loading: true };
        }
        return data;
      });
      props?.setClientLicenseData(updatedData);

      const obj = {
        Id: item?.id,
        IsPin: !item?.selected,
      };
      await updateEmployeeLicensesIsPinFlag(obj);

      updatedData = withoutHomeAndBuildIdOp.map((data, i) => {
        if (i === index) {
          return { ...data, loading: false, selected: !data.selected };
        }
        return data;
      });
      props?.setClientLicenseData(updatedData);
    } catch (err) {
      console.log("UpdateEmployeeLicensesIsPinFlag ERROR : ", err);
      const updatedData = withoutHomeAndBuildIdOp.map((data, i) => {
        if (i === index) {
          return { ...data, loading: false };
        }
        return data;
      });
      props?.setClientLicenseData(updatedData);
    }
  };

  useEffect(() => {
    props?.getList();
  }, []);

  return (
    <Modal open={props?.open} onClose={props?.handleClose}>
      <div className="modal-center" style={{ outline: "none" }}>
        <div
          className="modal-card"
          style={{
            height: "auto",
            width: "600px",
            outline: "none",
            overflowY: "auto",
            maxHeight: "500px",
            display: "flex",
            flexDirection: "column",
          }}>
          {/* Fixed header section */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              gap: "10px",
              marginLeft: "10px",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 1,
            }}>
            <IconButton
              color="primary"
              onClick={props?.handleClose}
              style={{ padding: "0px" }}>
              <Close />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "5px",
              position: "sticky",
              top: "50px", // Adjust according to your header's height
              backgroundColor: "white",
              zIndex: 1,
              marginBottom: "15px",
            }}>
            <Typography variant="body1">
              Pin your most used applications to quickly access them from the
              Homepage.
            </Typography>
          </div>

          {/* Scrollable content */}
          <div
            style={{
              overflowY: "auto",
              flexGrow: 1,
            }}>
            {withoutHomeAndBuildIdOp.map((item, index) => {
              return (
                <List
                  key={index}
                  disabled={item?.loading}
                  onClick={() => handleIconClick(item, index)}>
                  <Card
                    sx={{
                      marginBottom: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}>
                    <ListItemButton>
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText primary={<h4>{item?.appName}</h4>} />
                      <ListItemIcon
                        sx={{ marginLeft: "auto", cursor: "pointer" }}
                        onClick={() => handleIconClick(item, index)}
                        disabled={item?.loading}>
                        {item?.loading ? (
                          <CircularProgress
                            style={{
                              color: localStorage.getItem("clientAccentColor"),
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        ) : (
                          <PushPinIcon
                            style={{
                              color: item.selected
                                ? localStorage.getItem("clientAccentColor")
                                : BIGrey,
                            }}
                          />
                        )}
                      </ListItemIcon>
                    </ListItemButton>
                  </Card>
                </List>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
}