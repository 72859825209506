import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import {
  Visibility as ShowIcon,
  VisibilityOff as HideIcon,
} from "@material-ui/icons";
import BuildIDLogo from "./../../assets/images/Build-ID-Transparent.png";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader";
import AlertModal from "../../components/AlertModal";
import packageInfo from "../../../package.json";
import {
  signIn,
  userLogin,
  createAccount,
  savePersonalInfo,
  saveUserAddress,
  generateResetCode,
  isValidResetCode,
  saveNewPassword,
} from "../../api/UserApi";
import "../../assets/css/Login.css";
import {
  BIGrey,
  BIGreen,
  BIRed,
  BIYellow,
  BIBlue,
} from "../../assets/buildidColors";
import { FaCheck, FaTimes } from "react-icons/fa";
import { fetchUserTrades } from "../../api/TradesApi";
import Bowser from "bowser";
import TakeTour from "./takeatour/TakeTour";
import { getToken } from "../../firebase";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { useLocation, Redirect } from "react-router-dom";
import { firebase_app } from "../../firebase.js";
import googleLogo from "./../../assets/images/google-logo.png";
import fbLogo from "./../../assets/images/fb_logo.png";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import AppleIcon from "@mui/icons-material/Apple";
import { sha256 } from "crypto-hash";
import {
  ExperiencePage,
  FinishPage,
  ProfilePage,
  TradeAndCompanyPage,
} from "../referralsignup/ReferralSignUp";
import SafetyTicketSharingModal from "../dashboard/SafetyTicketSharingModal.jsx";
import moment from "moment";
import { SaveInvitationResponse } from "../../api/ClientApi.js";

let firstName = "";
let lastName = "";
let emailRegexp = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);

function isValidEmail(email) {
  return emailRegexp.test(email);
}

let bowserRes = Bowser.getParser(window.navigator.userAgent);

async function getDeviceId() {
  // const fp = await FingerprintJS.load();
  // const { visitorId } = await fp.get();
  // return visitorId;
  const visitorId = await getCurrentBrowserFingerPrint();
  return visitorId;
}

const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    let getNotificationtoken = await getToken();
    return getNotificationtoken;
  }
};

function Login(props) {
  const history = useHistory();
  const [alertModalTitle, setAlertModalTitle] = useState(null);
  const [alertModalBody, setAlertModalBody] = useState(null);
  const [email, setEmail] = useState("");
  const [copyEmail, setCopyemail] = useState(email);
  const [activeForm, setActiveForm] = useState(
    window.location.pathname === "/signup" ? "create-account" : "login"
  );
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  //onboarding proces
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isEmailPrivate, setIsEmailPrivate] = useState(false);
  const [emailFlag, setEmailFlag] = useState(false);

  const [changeEmailflag, setChangeEmailflag] = useState(false);

  useEffect(() => {
    switch (activeForm) {
      case "login":
        setCopyemail(email);
        break;
      case "forgot-password":
        if (changeEmailflag) {
          setCopyemail(copyEmail);
        } else {
          setCopyemail(email);
        }
        break;
      case "reset-password":
        setCopyemail(copyEmail);
        break;
      default:
        return <></>;
    }
  }, [activeForm]);

  function getActiveForm() {
    switch (activeForm) {
      case "login":
        return (
          <LoginForm
            history={history}
            email={email}
            setEmail={setEmail}
            setChangeEmailflag={setChangeEmailflag}
            screenName={props?.screenName}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setActiveForm={setActiveForm}
            setEmailFlag={setEmailFlag}
            setIsEmailPrivate={setIsEmailPrivate}
            itemId={props?.itemId}
            handleEmailTextfieldChange={(event) => {
              setEmail(event.target.value);
            }}
            onLogIn={props.onLogIn}
            onForgotClick={() => setActiveForm("forgot-password")}
            onCreateClick={() => {
              window.location.href = "create-account";
            }}
          />
        );
      case "forgot-password":
        return (
          <ForgotPasswordForm
            copyEmail={copyEmail}
            handleEmailTextfieldChange={(event) =>
              setCopyemail(event.target.value)
            }
            onBackClick={() => setActiveForm("login")}
            onResetClick={() => setActiveForm("reset-password")}
          />
        );
      case "reset-password":
        return (
          <ResetPasswordForm
            copyEmail={copyEmail}
            setChangeEmailflag={setChangeEmailflag}
            handleEmailTextfieldChange={(event) => {
              setCopyemail(event.target.value);
            }}
            onBackClick={() => setActiveForm("forgot-password")}
            onConfirmClick={() => setActiveForm("confirm-password")}
          />
        );
      case "confirm-password":
        return (
          <ConfirmPasswordForm
            email={copyEmail}
            handleEmailTextfieldChange={(event) =>
              setCopyemail(event.target.value)
            }
            onBackClick={() => setActiveForm("reset-password")}
            goToLoginForm={() => setActiveForm("login")}
          />
        );
      case "create-account":
        return (
          <CreateAccountForm
            history={history}
            email={email}
            handleEmailTextfieldChange={(event) => setEmail(event.target.value)}
            setAlertModalTitle={setAlertModalTitle}
            setAlertModalBody={setAlertModalBody}
            onLogIn={props.onLogIn}
            onBackClick={() => setActiveForm("login")}
            goToVerifyForm={() => setActiveForm("verify-email")}
          />
        );
      case "verify-email":
        return (
          <VerifyEmailForm
            email={email}
            handleEmailTextfieldChange={(event) => setEmail(event.target.value)}
            setAlertModalTitle={setAlertModalTitle}
            setAlertModalBody={setAlertModalBody}
            onBackClick={() => setActiveForm("create-account")}
            goToLoginForm={() => setActiveForm("login")}
          />
        );

      case "ProfilePage":
        return (
          <ProfilePage
            email={email}
            firstName={firstName}
            lastName={lastName}
            setFirstName={setFirstName}
            setLastName={setLastName}
            handleEmailTextfieldChange={(event) => {
              setIsEmailPrivate(false);
              setEmail(event.target.value);
            }}
            setEmail={setEmail}
            isEmailPrivate={isEmailPrivate}
            emailFlag={emailFlag}
            setEmailFlag={setEmailFlag}
            onNextClick={() => setActiveForm("TradeAndCompanyPage")}
          />
        );
      case "TradeAndCompanyPage":
        return (
          <TradeAndCompanyPage
            onNextClick={() => setActiveForm("ExperiencePage")}
          />
        );
      case "ExperiencePage":
        return (
          <ExperiencePage onNextClick={() => setActiveForm("FinishPage")} />
        );

      case "FinishPage":
        return <FinishPage />;

      default:
        return <></>;
    }
  }

  return (
    <div className="login">
      <AlertModal
        isOpen={alertModalTitle !== null || alertModalBody !== null}
        onClose={() => {
          setAlertModalTitle(null);
          setAlertModalBody(null);
        }}
        title={alertModalTitle}
        body={alertModalBody}
        expCloseIcon={true}
      />

      <div
        className={
          activeForm == "ProfilePage" ||
            activeForm == "TradeAndCompanyPage" ||
            activeForm == "ExperiencePage" ||
            activeForm == "FinishPage"
            ? "login-onboarding-form"
            : "login-form"
        }
      >
        {getActiveForm()}
      </div>

      {activeForm == "ProfilePage" ||
        activeForm == "TradeAndCompanyPage" ||
        activeForm == "ExperiencePage" ||
        activeForm == "FinishPage" ? (
        <></>
      ) : (
        <div className="login-splash">
          <div className="login-splash-content">
            {/* <h1>Welcome to<br />Build<b>ID</b></h1> */}
            <div className="login-buildid-logo-conatiner">
              <img className="login-buildid-logo" src={BuildIDLogo} alt="" />
            </div>
            <h3>
              Showcase the sites that you have been a part of that make you be
              proud of working in construction.
            </h3>
            <h3>
              Earn BuilderBucks that you can exchange for chances to win prizes,
              tools, and other cool things!
            </h3>
          </div>
          <div className="login-fotter">
            <div>
              <span>{`v.${packageInfo?.version}`}</span>
            </div>
            <div>
              <a
                href="https://www.iubenda.com/terms-and-conditions/90161377"
                target="_blank"
              >
                Terms & Conditions
              </a>
            </div>
            <div>
              <a
                href="https://www.iubenda.com/privacy-policy/90161377"
                target="_blank"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;

function LoginForm(props) {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [isEmptyPassword, setIsEmptyPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isIncorrectPassword, setIsIncorrectPassword] = useState(false);
  const [notifyToken, setNotifytoken] = useState("");
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [popUpAlertOpen, setPopUpAlertOpen] = useState(false);
  var isAndroid = /Android/i.test(navigator.userAgent);
  var isIphone = /iPhone/i.test(navigator.userAgent);
  var isIpad = /iPad/i.test(navigator.userAgent);

  const [showModal, setShowModal] = useState(false);
  const [clientId, setClientId] = useState(null);

  const history = useHistory();

  const hash = (str) => {
    return sha256(str);
  };

  useEffect(async () => {
    const tok = await requestNotificationPermission();
    setNotifytoken(tok);
    props.setChangeEmailflag(false);
  }, []);

  async function handleLoginClick(event) {
    event.preventDefault();
    try {
      if (props.email === "") {
        setIsEmptyEmail(true);
        setIsValid(false);
        if (password === "") {
          setIsEmptyPassword(true);
          return false;
        } else setIsEmptyPassword(false);
        return false;
      } else {
        setIsEmptyEmail(false);
        const email = props.email;
        if (isValidEmail(email)) {
          setIsValid(false);
          setIsEmptyEmail(false);
        } else {
          setIsValid(true);
          if (password === "") {
            setIsEmptyPassword(true);
            return false;
          } else setIsEmptyPassword(false);
          return false;
        }
      }

      if (password === "") {
        setIsEmptyPassword(true);
        return false;
      } else setIsEmptyPassword(false);

      const pwd = await hash(password);

      const deviceId = await getDeviceId();

      const loginObj = {
        DeviceId: deviceId,
        Email: props?.email,
        Password: pwd,
        AppName: "BuilID-Web",
        AppVersion: packageInfo?.version,
        Browser: bowserRes.parsedResult.browser?.name,
        BrowserVersion: bowserRes.parsedResult.browser?.version,
        Platform: bowserRes.parsedResult.os.name,
        PlatformVersion: "NA",
        DeviceName: "Browser",
        NotifyToken: notifyToken ?? null,
        LoginWith: "Password",
      };

      let loginResult = await userLogin(loginObj);

      const {
        isFirstTimeLoggedIn,
        UserId,
        DeviceId,
        FirstName,
        LastName,
        Email,
        UserReferralCode,
        ClientsList
      } = loginResult?.data?.data?.userInformation;

      if (ClientsList.length > 0) {
        let filteredData =
          ClientsList?.filter(
            (obj) => obj?.OrganizationName !== "BI Free"
          );

        if (filteredData?.length > 0)
          await localStorage.setItem(
            "clientList",
            JSON.stringify(filteredData)
          );

      }


      const { Status, ClientId } =
        loginResult?.data?.data?.userInformation?.ClientInvitation;
      setClientId(ClientId ?? null);

      await localStorage.setItem(
        "token",
        loginResult?.data?.data?.accessToken ?? null
      );

      localStorage?.setItem("loginWith", "Email");
      await localStorage.setItem("deviceId", DeviceId ?? null);
      await localStorage.setItem("firstName", FirstName ?? null);
      await localStorage.setItem("lastName", LastName ?? null);
      await localStorage.setItem("surveyLaterToday", "false");
      await localStorage.setItem("email", Email ?? null);
      await localStorage.setItem("primaryEmail", Email ?? null);

      await localStorage.setItem("UserReferralCode", UserReferralCode ?? null);
      localStorage.setItem(
        "pictureUrl",
        loginResult?.data?.data?.pictureUrl || ""
      );
      await localStorage.setItem("userId", UserId);


      if (loginResult?.data?.data?.accessToken) {
        const tutorial = {
          dashboard: "true",
          sites: "true",
          montages: "true",
          ticket: "true",
          jobPostings: "true",
          carpool: "true",
          resumeBuilder: "true",
          contentSearch: "true",
        };
        localStorage.setItem("tutorial", JSON.stringify(tutorial));

        const obj = {
          SearchList: [{ userId: Number(UserId), tradeType: "Main" }],
        };

        let header = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        };

        fetchUserTrades(obj, header).then((resTrade) => {
          localStorage.setItem("tradeName", resTrade[0]?.tradeName || null);
          localStorage.setItem("tradeId", resTrade[0]?.tradeId || null);
        });
        await localStorage.setItem("surveyLaterToday", "false");

        if (isFirstTimeLoggedIn) {
          localStorage.setItem("newUser", "true");
        }

        if (Status === 1) {
          return setShowModal(true);
        }

        if (loginResult.data?.message === "OK") {
          if (isFirstTimeLoggedIn) {
            localStorage.setItem("newUser", "true");
            // console.log("Populating new account with blank info...");
          } else {
            window.location.pathname = "/dashboard";
          }

          if (props?.screenName) {
            switch (props?.screenName) {
              case "TradeDetail":
                window.location.href = "trade/" + props?.itemId;
                break;
              case "ProdcutInfo":
                window.location.href = "product/" + props?.itemId;
                break;
              case "CompanyInfo":
                window.location.href = "company/" + props?.itemId;
                break;
              case "SiteDetail":
                window.location.href = "sites/site/" + props?.itemId;
                break;
              case "ManufacturerInfo":
                window.location.href = "manufacturer/" + props?.itemId;
                break;
              default:
                // window.location.href = "/dashboard";
                window.location.replace("/dashboard");
            }
          } else {
            if (pathname === "/") history.push("/dashboard"); //props.history.push("/dashboard");
          }
        } // Redirect on login
      } else {
        setIsIncorrectPassword(true);
        setPassword("");
        return;
      }

      // try{
    } catch (error) {
      console.log("ERROR:::", error);
      setIsIncorrectPassword(true);
      setPassword("");
    }
  }

  const googleAuthHandler = async () => {
    const auth = getAuth(firebase_app);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setIsLoading(true);
      const deviceId = await getDeviceId();

      const googleLoginObj = {
        DeviceId: deviceId, // Send whenever available at ur end
        Email: result._tokenResponse?.email,
        AppName: "BuilID-Web",
        AppVersion: packageInfo?.version,
        Browser: bowserRes.parsedResult.browser?.name,
        BrowserVersion: bowserRes.parsedResult.browser?.version,
        Platform: bowserRes.parsedResult.os.name, // ios/android/windows/macos
        PlatformVersion: "NA",
        DeviceName: "Browser",
        NotifyToken: notifyToken ?? null,
        LoginWith: "Google", // Facebook,Google,Password,
        IdentityToken: result._tokenResponse.oauthIdToken, // Google , Facebook Tokens
      };

      const uName = result?._tokenResponse?.displayName.split(" ");

      let loginResult = await userLogin(googleLoginObj);
      const { DeviceId, UserId, isFirstTimeLoggedIn } =
        loginResult?.data?.data?.userInformation;
      loginResult.data.data.userInformation.FirstName = String(uName[0]) || "";
      loginResult.data.data.userInformation.LastName =
        String(uName[uName.length - 1]) || "";
      if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
        // const clientsWithLicense = loginResult?.data?.data?.userInformation?.ClientsList?.filter(client => Array.isArray(client.Licences)) || [];
        // loginResult?.data?.data.userInformation.ClientsList?.forEach((obj) => {
        //   if (obj.Licences) {
        //     obj.noOfLicense = obj.Licences.length;
        //   } else {
        //     obj.noOfLicense = 0;
        //   }
        // });
        // let filteredData =
        //   loginResult?.data?.data.userInformation.ClientsList?.filter(
        //     (obj) => obj.noOfLicense !== 0
        //   );

        let filteredData =
          loginResult?.data?.data.userInformation.ClientsList?.filter(
            (obj) => obj.OrganizationName !== "BI Free"
          );

        if (filteredData.length > 0)
          await localStorage.setItem(
            "clientList",
            JSON.stringify(filteredData)
          );
      }

      await localStorage.setItem(
        "token",
        loginResult?.data?.data?.accessToken ?? null
      );
      await localStorage.setItem("deviceId", DeviceId ?? null);
      await localStorage.setItem("userId", UserId);
      await localStorage.setItem("surveyLaterToday", "false");

      if (loginResult?.data.data.accessToken) {
        localStorage.setItem("email", result?._tokenResponse?.email || "");
        props?.setEmail(result?._tokenResponse?.email || "");
        localStorage.setItem(
          "primaryEmail",
          result._tokenResponse?.email || ""
        );
        localStorage.setItem(
          "firstName",
          result._tokenResponse?.firstName || ""
        );
        localStorage.setItem("lastName", result._tokenResponse?.lastName || "");
        props?.setFirstName(result?._tokenResponse?.firstName || "");
        props?.setLastName(result?._tokenResponse?.lastName || "");
        localStorage.setItem("pictureUrl", loginResult?.data?.data?.pictureUrl);
        localStorage.setItem("loginWith", "Google");
        // localStorage.setItem("IsPrivateEmail", false);
        // props?.setIsEmailPrivate(true);
        props?.setEmailFlag(true);

        const tutorial = {
          dashboard: "true",
          sites: "true",
          montages: "true",
          ticket: "true",
          jobPostings: "true",
          carpool: "true",
          resumeBuilder: "true",
          contentSearch: "true",
        };
        localStorage.setItem("tutorial", JSON.stringify(tutorial));

        const obj = {
          SearchList: [
            {
              userId: Number(UserId),
              tradeType: "Main",
            },
          ],
        };

        let header = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        };

        fetchUserTrades(obj, header).then((resTrade) => {
          localStorage.setItem("tradeName", resTrade[0]?.tradeName || null);
          localStorage.setItem("tradeId", resTrade[0]?.tradeId || null);
        });

        if (loginResult.data?.message === "OK") {
          if (isFirstTimeLoggedIn) {
            localStorage.setItem("newUser", "true");
            props?.setActiveForm("ProfilePage");

            return;
          } else {
            // window.location.href = "/dashboard";
            window.location.replace("/dashboard");
          }

          if (props?.screenName) {
            switch (props?.screenName) {
              case "TradeDetail":
                window.location.href = "trade/" + props?.itemId;
                break;
              case "ProdcutInfo":
                window.location.href = "product/" + props?.itemId;
                break;
              case "CompanyInfo":
                window.location.href = "company/" + props?.itemId;
                break;
              case "SiteDetail":
                window.location.href = "sites/site/" + props?.itemId;
                break;
              case "ManufacturerInfo":
                window.location.href = "manufacturer/" + props?.itemId;
                break;
              default:
                window.location.replace("/dashboard");
            }
          } else {
            if (pathname === "/") window.location.replace("/dashboard");
          }
        }
      } else {
        console.log("LOGIN RESPONSE IS NOT OK!");
      }
    } catch (err) {
      console.log("GOOGLE AUTH ERROR IS >>>", err);
    } finally {
      setIsLoading(false);
    }
  };

  const fbAuthHandler = async () => {
    const auth = getAuth(firebase_app);
    const provider = new FacebookAuthProvider();
    const deviceId = await getDeviceId();
    try {
      provider.setCustomParameters({ display: "popup" });
      const result = await signInWithPopup(auth, provider);
      console.log("result-->", result);
      // As first time if I login to fb account
      const fbAuthObj = {
        Email: result?.user?.email,
        NotifyToken: notifyToken ?? null,
        AppName: "BuilID-Web", //<--- Changes for Mobile development
        AppVersion: packageInfo?.version,
        Browser: bowserRes.parsedResult.browser?.name,
        BrowserVersion: bowserRes.parsedResult.browser?.version,
        Platform: bowserRes.parsedResult.os.name, // <--- can be ios,android,windows,macos
        PlatformVersion: "NA",
        DeviceName: "Browser",
        DeviceId: deviceId,
        IdentityToken: result._tokenResponse.oauthAccessToken,
        UserID: result?.user?.providerData?.[0]?.uid ?? 0,
        LoginWith: "Facebook",
      };
      console.log(
        "result._tokenResponse.oauthaccessToken-->",
        result._tokenResponse.oauthAccessToken
      );
      console.log("fbAuthObj-->", fbAuthObj);
      const loginResult = await userLogin(fbAuthObj);
      const { isFirstTimeLoggedIn, UserId, FirstName, LastName, email } =
        loginResult?.data?.data?.userInformation;
      if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
        // const clientsWithLicense = loginResult?.data?.data?.userInformation?.ClientsList?.filter(client => Array.isArray(client.Licences)) || [];
        // loginResult?.data?.data.userInformation.ClientsList?.forEach((obj) => {
        //   if (obj.Licences) {
        //     obj.noOfLicense = obj.Licences.length;
        //   } else {
        //     obj.noOfLicense = 0;
        //   }
        // });
        // let filteredData =
        //   loginResult?.data?.data.userInformation.ClientsList?.filter(
        //     (obj) => obj.noOfLicense !== 0
        //   );
        let filteredData =
          loginResult?.data?.data.userInformation.ClientsList?.filter(
            (obj) => obj.OrganizationName !== "BI Free"
          );
        if (filteredData.length > 0)
          await localStorage.setItem(
            "clientList",
            JSON.stringify(filteredData)
          );
      }

      await localStorage.setItem(
        "token",
        loginResult?.data?.data?.accessToken ?? null
      );
      await localStorage.setItem(
        "deviceId",
        loginResult?.data?.data?.deviceId ?? null
      );
      await localStorage.setItem("userId", UserId);

      if (loginResult?.data?.data?.accessToken) {
        localStorage.setItem("email", email || result._tokenResponse.email);
        props?.setEmail(email || result._tokenResponse.email || "");
        localStorage.setItem(
          "primaryEmail",
          email || result._tokenResponse.email
        );
        localStorage.setItem("firstName", FirstName || "");
        localStorage.setItem("lastName", LastName || "");
        props?.setFirstName(FirstName || "");
        props?.setLastName(LastName || "");
        localStorage.setItem("loginWith", "Facebook");
        await localStorage.setItem("surveyLaterToday", "false");
        localStorage.setItem("pictureUrl", loginResult?.data?.data?.pictureUrl);
        // localStorage.setItem("IsPrivateEmail", false);
        // props?.setIsEmailPrivate(true);
        props?.setEmailFlag(true);
        const tutorial = {
          dashboard: "true",
          sites: "true",
          montages: "true",
          ticket: "true",
          jobPostings: "true",
          carpool: "true",
          resumeBuilder: "true",
          contentSearch: "true",
        };
        localStorage.setItem("tutorial", JSON.stringify(tutorial));

        if (loginResult.data?.message === "OK") {
          if (isFirstTimeLoggedIn) {
            localStorage.setItem("newUser", "true");
            props?.setActiveForm("ProfilePage");
            return;
          } else {
            window.location.replace("/dashboard");
          }
          if (props?.screenName) {
            switch (props?.screenName) {
              case "TradeDetail":
                window.location.href = "trade/" + props?.itemId;
                break;
              case "ProdcutInfo":
                window.location.href = "product/" + props?.itemId;
                break;
              case "CompanyInfo":
                window.location.href = "company/" + props?.itemId;
                break;
              case "SiteDetail":
                window.location.href = "sites/site/" + props?.itemId;
                break;
              case "ManufacturerInfo":
                window.location.href = "manufacturer/" + props?.itemId;
                break;
              default:
                window.location.replace("/dashboard");
                console.log(" window.location.replace");
            }
          } else {
            if (pathname === "/") window.location.replace("/dashboard");
            console.log(" window.location.replace");
          }
        }
      } else {
        console.log("LOGIN RESPONSE IS NOT OK!");
      }
    } catch (err) {
      console.log("result-->", err);

      if (err.code === "auth/popup-blocked") {
        // Fallback to redirect if popup is blocked
        if (isIphone || isIpad) setPopUpAlertOpen(true);
        return;
        // await signInWithRedirect(auth, provider);
        // setFacebookPopupBlockFlag(true);
      } else {
        console.error("Error during sign-in:", err);
      }

      if (err.code === "auth/account-exists-with-different-credential") {
        // Get the pending credential (Facebook credential)
        const result = err.customData;
        const userId = JSON.parse(err.customData._tokenResponse.rawUserInfo);
        console.log("result--->", result);

        const fbAuthObj = {
          Email: result._tokenResponse.email || result?.user?.email,
          NotifyToken: notifyToken ?? null,
          AppName: "BuilID-Web", //<--- Changes for Mobile development
          AppVersion: packageInfo?.version,
          Browser: bowserRes.parsedResult.browser?.name,
          BrowserVersion: bowserRes.parsedResult.browser?.version,
          Platform: bowserRes.parsedResult.os.name, // <--- can be ios,android,windows,macos
          PlatformVersion: "NA",
          DeviceName: "Browser",
          DeviceId: deviceId,
          IdentityToken: "",
          UserID: userId.id ?? 0,
          LoginWith: "Facebook",
        };
        console.log("fbAuthObj", fbAuthObj);
        const loginResult = await userLogin(fbAuthObj);
        const { isFirstTimeLoggedIn, UserId, FirstName, LastName, email } =
          loginResult?.data?.data?.userInformation;
        if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
          // const clientsWithLicense = loginResult?.data?.data?.userInformation?.ClientsList?.filter(client => Array.isArray(client.Licences)) || [];
          // loginResult?.data?.data.userInformation.ClientsList?.forEach(
          //   (obj) => {
          //     if (obj.Licences) {
          //       obj.noOfLicense = obj.Licences.length;
          //     } else {
          //       obj.noOfLicense = 0;
          //     }
          //   }
          // );
          // let filteredData =
          //   loginResult?.data?.data.userInformation.ClientsList?.filter(
          //     (obj) => obj.noOfLicense !== 0
          //   );
          let filteredData =
            loginResult?.data?.data.userInformation.ClientsList?.filter(
              (obj) => obj.OrganizationName !== "BI Free"
            );
          if (filteredData.length > 0)
            await localStorage.setItem(
              "clientList",
              JSON.stringify(filteredData)
            );
        }
        await localStorage.setItem(
          "token",
          loginResult?.data?.data?.accessToken ?? null
        );
        await localStorage.setItem(
          "deviceId",
          loginResult?.data?.data?.deviceId ?? null
        );
        await localStorage.setItem("userId", UserId);

        if (loginResult?.data?.data?.accessToken) {
          localStorage.setItem("email", email || result._tokenResponse.email);
          props?.setEmail(email || result._tokenResponse.email || "");
          localStorage.setItem(
            "primaryEmail",
            email || result._tokenResponse.email
          );
          await localStorage.setItem("surveyLaterToday", "false");
          localStorage.setItem("firstName", FirstName || "");
          localStorage.setItem("lastName", LastName || "");
          props?.setFirstName(FirstName || "");
          props?.setLastName(LastName || "");
          localStorage.setItem("loginWith", "Facebook");
          localStorage.setItem(
            "pictureUrl",
            loginResult?.data?.data?.pictureUrl
          );
          // localStorage.setItem("IsPrivateEmail", false);
          // props?.setIsEmailPrivate(true);
          props?.setEmailFlag(true);
          const tutorial = {
            dashboard: "true",
            sites: "true",
            montages: "true",
            ticket: "true",
            jobPostings: "true",
            carpool: "true",
            resumeBuilder: "true",
            contentSearch: "true",
          };
          localStorage.setItem("tutorial", JSON.stringify(tutorial));

          if (loginResult.data?.message === "OK") {
            console.log("props?.screenName", props?.screenName);
            if (isFirstTimeLoggedIn) {
              localStorage.setItem("newUser", "true");
              props?.setActiveForm("ProfilePage");
              return;
            } else {
              window.location.replace("/dashboard");
              console.log(" window.location.replace");
            }
            if (props?.screenName) {
              switch (props?.screenName) {
                case "TradeDetail":
                  window.location.href = "trade/" + props?.itemId;
                  break;
                case "ProdcutInfo":
                  window.location.href = "product/" + props?.itemId;
                  break;
                case "CompanyInfo":
                  window.location.href = "company/" + props?.itemId;
                  break;
                case "SiteDetail":
                  window.location.href = "sites/site/" + props?.itemId;
                  break;
                case "ManufacturerInfo":
                  window.location.href = "manufacturer/" + props?.itemId;
                  break;
                default:
                  window.location.replace("/dashboard");
                  console.log(" window.location.replace");
              }
            } else {
              if (pathname === "/") window.location.replace("/dashboard");
              console.log(" window.location.replace");
            }
          }
        } else {
          console.log("LOGIN RESPONSE IS NOT OK!");
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const appleLogin = async () => {
    const auth = getAuth(firebase_app);
    const provider = new OAuthProvider("apple.com");
    const deviceId = await getDeviceId();
    try {
      provider.addScope("email");
      provider.addScope("name");
      const result = await signInWithPopup(auth, provider);
      setIsLoading(true);
      const appleAuthObj = {
        Email: result?.user?.email,
        Password: "",
        NotifyToken: notifyToken ?? null,
        AppName: "BuilID-Web", //<--- Changes for Mobile development
        AppVersion: packageInfo?.version,
        Browser: bowserRes.parsedResult.browser?.name,
        BrowserVersion: bowserRes.parsedResult.browser?.version,
        Platform: bowserRes.parsedResult.os.name, // <--- can be ios,android,windows,macos
        PlatformVersion: "NA",
        DeviceName: "Browser",
        DeviceId: deviceId,
        IdentityToken: result._tokenResponse.oauthIdToken,
        UserID: result?.user?.providerData?.[0]?.uid ?? 0,
        LoginWith: "Apple",
      };

      const loginResult = await userLogin(appleAuthObj); //! re-rendering

      console.log("loginResult ---->", loginResult);

      const {
        isFirstTimeLoggedIn,
        UserId,
        FirstName,
        LastName,
        Email,
        IsPrivateEmail,
        DeviceId,
      } = loginResult?.data?.data?.userInformation;
      if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
        loginResult?.data?.data.userInformation.ClientsList?.forEach((obj) => {
          if (obj.Licences) {
            obj.noOfLicense = obj.Licences.length;
          } else {
            obj.noOfLicense = 0;
          }
        });
        let filteredData =
          loginResult?.data?.data.userInformation.ClientsList?.filter(
            (obj) => obj.noOfLicense !== 0
          );
        if (filteredData.length > 0)
          await localStorage.setItem(
            "clientList",
            JSON.stringify(filteredData)
          );
      }
      await localStorage.setItem(
        "token",
        loginResult?.data?.data?.accessToken ?? null
      );
      await localStorage.setItem("deviceId", DeviceId ?? null);
      await localStorage.setItem("userId", UserId);

      if (loginResult?.data?.data?.accessToken) {
        localStorage.setItem("email", Email || "");
        if (IsPrivateEmail) {
          props?.setEmail("");
        } else {
          props?.setEmail(Email || "");
        }
        localStorage.setItem("primaryEmail", Email);
        localStorage.setItem("firstName", FirstName || "");
        localStorage.setItem("lastName", LastName || "");
        props?.setFirstName(FirstName || "");
        props?.setLastName(LastName || "");
        localStorage.setItem("loginWith", "Apple");
        localStorage.setItem("IsPrivateEmail", IsPrivateEmail);
        props?.setIsEmailPrivate(IsPrivateEmail);
        localStorage.setItem("pictureUrl", loginResult?.data?.data?.pictureUrl);
        const tutorial = {
          dashboard: "true",
          sites: "true",
          montages: "true",
          ticket: "true",
          jobPostings: "true",
          carpool: "true",
          resumeBuilder: "true",
          contentSearch: "true",
        };
        localStorage.setItem("tutorial", JSON.stringify(tutorial));

        if (loginResult.data?.message === "OK") {
          if (isFirstTimeLoggedIn) {
            localStorage.setItem("newUser", "true");
            props?.setActiveForm("ProfilePage");
          } else {
            window.location.replace("/dashboard");
            console.log(" window.location.replace");
          }
          if (props?.screenName) {
            switch (props?.screenName) {
              case "TradeDetail":
                window.location.href = "trade/" + props?.itemId;
                break;
              case "ProdcutInfo":
                window.location.href = "product/" + props?.itemId;
                break;
              case "CompanyInfo":
                window.location.href = "company/" + props?.itemId;
                break;
              case "SiteDetail":
                window.location.href = "sites/site/" + props?.itemId;
                break;
              case "ManufacturerInfo":
                window.location.href = "manufacturer/" + props?.itemId;
                break;
              default:
                window.location.replace("/dashboard");
                console.log(" window.location.replace");
            }
          }
        }
      } else {
        console.log("LOGIN RESPONSE IS NOT OK!");
      }
    } catch (error) {
      if (
        error.code === "auth/popup-blocked" ||
        error.code === "auth/popup-closed-by-user"
      ) {
        // Fallback to redirect if popup is blocked
        if (isIphone || isIpad) setPopUpAlertOpen(true);
        return;
        // await signInWithRedirect(auth, provider)
        // setApplePopupBlockFlag(true);
      } else {
        console.error("Error during sign-in:", error);
      }
      console.error("Error during sign in with Apple: ", error);
    }
  };

  useEffect(() => {
    setIsEmptyEmail(false);
    setIsEmptyPassword(false);
    firstName = localStorage.getItem("firstName");
    lastName = localStorage.getItem("lastName");
    // handlePermissionRequest();
  }, []);

  const navigateBasedOnUrl = () => {
    if (props?.screenName) {
      switch (props?.screenName) {
        case "TradeDetail":
          window.location.href = "trade/" + props?.itemId;
          break;
        case "ProdcutInfo":
          window.location.href = "product/" + props?.itemId;
          break;
        case "CompanyInfo":
          window.location.href = "company/" + props?.itemId;
          break;
        case "SiteDetail":
          window.location.href = "sites/site/" + props?.itemId;
          break;
        case "ManufacturerInfo":
          window.location.href = "manufacturer/" + props?.itemId;
          break;
        default:
          // window.location.href = "/dashboard";
          window.location.replace("/dashboard");
      }
    } else {
      if (pathname === "/") window.location.replace("/dashboard"); //props.history.push("/dashboard");
    }
  };

  const onActionSuccesOfThermalSystems = async (type) => {
    try {
      const obj = {
        id: 0,
        ClientId: clientId,
        UserEmail: props.email,
        Date: moment(),
        Status: type === "Agree" ? "Accepted" : "Rejected",
      };

      const res1 = await SaveInvitationResponse(obj);

      if(res1?.data){
        let clientList = JSON.parse(localStorage.getItem("clientList")) || [];
        clientList.push(res1?.data);
        await localStorage.setItem(
          "clientList",
          JSON.stringify(clientList)
        )
      }
      navigateBasedOnUrl();
    } catch (error) {
      console.log("Error while updating status :: ", error);
      navigateBasedOnUrl();
    }
  };

  return (
    <div>
      <AlertModal
        isOpen={isIncorrectPassword}
        onClose={() => setIsIncorrectPassword(false)}
        title={"Login Error"}
        body={"Invalid email and/or password."}
        expCloseIcon={true}
      />

      {popUpAlertOpen && (
        <AlertModal
          inappropriateImage
          isOpen={popUpAlertOpen}
          onClose={() => setPopUpAlertOpen(false)}
          title={"Enable Pop-ups for a Better Experience"}
          body={
            <>
              It looks like <strong>Safari's pop-up blocker</strong> is
              currently enabled, which may prevent you from signing in. To
              continue, please disable the 'Block Pop-ups' setting for this
              site.
            </>
          }
          // text1={"Open the Settings app on your device. Scroll down and tap Safari."}
          text1={
            <>
              Open the <strong>Settings</strong> app on your device {">"} <br />
              Scroll down and tap <strong>Safari</strong> {">"} <br />
              Scroll down and Toggle off <strong>Block Pop-ups</strong>.
            </>
          }
        />
      )}

      {isLoading ? (
        <div className="login-form-content">
          <Loader />
        </div>
      ) : (
        <div className="login-form-content">
          <h3 className="login-type-title">Login</h3>
          <form>
            <div className="input-container">
              <TextField
                variant="outlined"
                label="Email"
                fullWidth
                defaultValue={props.email}
                onChange={props.handleEmailTextfieldChange}
                error={isEmptyEmail || isValid}
                helperText={
                  isEmptyEmail
                    ? "This field is required."
                    : isValid
                      ? "Please enter a valid email address"
                      : ""
                }
              />
            </div>

            <div className="input-container">
              <TextField
                id="password-textfield"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                label="Password"
                fullWidth
                // defaultValue={password}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                error={isEmptyPassword}
                helperText={isEmptyPassword ? "This field is required." : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1" // Unfocusable button
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {/* {showPassword ? <HideIcon /> : <ShowIcon />} */}
                        {showPassword ? <ShowIcon /> : <HideIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") handleLoginClick(event);
                }}
              />
            </div>
          </form>

          <div className="flex-container-horizontal">
            <div style={{ width: "50%" }}>
              <div className="input-container">
                <Button
                  size="small"
                  color="primary"
                  onClick={props.onForgotClick}
                >
                  Forgot Password
                </Button>
              </div>
            </div>
            <div style={{ width: "50%", float: "right", textAlign: "right" }}>
              <div className="input-container">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoginClick}
                >
                  Login
                </Button>
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <TakeTour />
          </div>

          {/* <div className="divider">
            <p>
              <span>or</span>
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid lightgray",
              paddingTop: 7,
              paddingBottom: 7,
              borderRadius: 8,
              cursor: "pointer",
              gap: "10px",
            }}
            onClick={googleAuthHandler}
          >
            <img src={googleLogo} width={24} height={24} alt="Loading.." />
            <p>Continue with Google</p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid lightgray",
              paddingTop: 7,
              paddingBottom: 7,
              borderRadius: 8,
              cursor: "pointer",
              gap: "10px",
              marginTop: "10px",
            }}
            onClick={fbAuthHandler}
          >
            <img src={fbLogo} width={24} height={24} alt="Loading.." />
            <p>Continue with Facebook</p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid lightgray",
              paddingTop: 7,
              paddingBottom: 7,
              borderRadius: 8,
              cursor: "pointer",
              gap: "10px",
              marginTop: "10px",
            }}
            onClick={appleLogin}
          >
            <AppleIcon />
            <p>Continue with Apple</p>
          </div> */}

          <div style={{ textAlign: "center", margin: "10px 0px" }}>
            <Button color="primary" onClick={props.onCreateClick}>
              Create New Account
            </Button>
          </div>
        </div>
      )}
      {showModal && (
        <SafetyTicketSharingModal
          open={showModal}
          onActionSuccesOfThermalSystems={onActionSuccesOfThermalSystems}
          clientId={clientId}
        />
      )}
    </div>
  );
}

function ForgotPasswordForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [notExistsEmail, setNotExistsEmail] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  async function handlePasswordRecoveryClick(event) {
    event.preventDefault();
    try {
      if (props?.copyEmail === "") {
        setIsInvalidEmail(false);
        return false;
      } else {
        const email = props.copyEmail;
        if (isValidEmail(email)) {
          setIsInvalidEmail(false);
        } else {
          setIsInvalidEmail(true);
          return false;
        }
      }
      setIsLoading(true); // Show loading animation
      const res = await generateResetCode(props.copyEmail);

      if (res?.status) {
        // setEmailSent(false);
        props.onResetClick();
      } else {
        setEmailErrorMessage(res?.errorMessage);
        setNotExistsEmail(true);
      }

      // if (res?.status == false) {
      //   setEmailErrorMessage(res?.errorMessage);
      //   setNotExistsEmail(true);
      // }

      setIsLoading(false);
    } catch (error) {
      console.log("Error ===>", error);
      // TODO Catches undefined error when invalid email is submitted?
      setIsLoading(false); // Clear loading animation
    }
  }

  return (
    <div className="login-form-content">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* <AlertModal
            isOpen={emailSent}
            onClose={() => {
              setEmailSent(false);
              props.onResetClick();
            }}
            title={"Reset Code"}
            body={
              "If there is an account associated with this email, you will receive a reset code."
            }
          /> */}

          <AlertModal
            isOpen={notExistsEmail}
            onClose={() => {
              setNotExistsEmail(false);
              setEmailErrorMessage("");
            }}
            body={emailErrorMessage}
            expCloseIcon={true}
          />

          <AlertModal
            isOpen={isInvalidEmail}
            onClose={() => {
              setIsInvalidEmail(false);
            }}
            body={"Please enter a valid email address."}
            expCloseIcon={true}
          />

          <h3 className="login-type-title">Forgot Password</h3>
          <form>
            <div className="input-container">
              <TextField
                variant="outlined"
                label="Email"
                fullWidth
                value={props.copyEmail}
                onChange={props.handleEmailTextfieldChange}
                error={props?.isInvalidEmail && props?.email != ""}
                // helperText={
                //   props?.isInvalidEmail && props?.email != ""
                //     ? "Please enter a valid email address."
                //     : ""
                // }
                onKeyPress={(event) => {
                  if (event.key === "Enter") handlePasswordRecoveryClick(event);
                }}
              />
            </div>
          </form>

          <div className="flex-container-horizontal">
            <div className="flex-container-left">
              <div className="input-container">
                <Button
                  size="small"
                  color="primary"
                  onClick={props.onBackClick}
                >
                  Back
                </Button>
              </div>
            </div>

            <div className="flex-container-right">
              <div className="input-container">
                <Button
                  disabled={props.copyEmail == ""}
                  variant="contained"
                  color="primary"
                  onClick={handlePasswordRecoveryClick}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>

          <div className="input-container">
            <Button
              size="small"
              color="primary"
              onClick={handlePasswordRecoveryClick}
            >
              I have a reset code
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

function ResetPasswordForm(props) {
  const [isLoading, setIsLoading] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [isEmptyResetCode, setIsEmptyResetCode] = useState(false);
  const [isEmptyPassword, setIsEmptyPassword] = useState(false);
  const [isEmptyRetypePassword, setIsEmptyRetypePassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [alertModalOpen_ResetCodeError, setAlertModalOpen_ResetCodeError] =
    useState(false);
  const [alertModalOpen_EmailError, setAlertModalOpen_EmailError] =
    useState(false);
  const [alertModalOpen_PasswordChange, setAlertModalOpen_PasswordChange] =
    useState(false);
  const [passwordStrengthCount, setPasswordStrengthCount] = React.useState(0);
  const [emailSent, setEmailSent] = useState(false);
  const [notifyToken, setNotifytoken] = useState("");
  const [isIncorrectResetCode, setIsIncorrectResetCode] = useState(false);
  const [isResetCodeFlag, setIsResetCodeFlag] = useState(false);

  const hash = (str) => {
    return sha256(str);
  };

  useEffect(async () => {
    const tok = await requestNotificationPermission();
    setNotifytoken(tok);
    props.setChangeEmailflag(true);
  }, []);

  async function handleResetPasswordClick() {
    const deviceName = await getDeviceId();
    try {
      if (resetCode === "") {
        setIsEmptyResetCode(true);
        return false;
      } else setIsEmptyResetCode(false);

      // Check if passwords match

      const obj = JSON.stringify({
        email: props.copyEmail,
        resetCode: resetCode,
        NotifyToken: notifyToken ?? null,
        AppName: "BuilID-Web",
        AppVersion: packageInfo?.version,
        Browser: bowserRes.parsedResult.browser?.name,
        BrowserVersion: bowserRes.parsedResult.browser?.version,
        Platform: bowserRes.parsedResult.os.name,
        PlatformVersion: "NA",
        DeviceName: deviceName,
      });

      const resValid = await isValidResetCode(obj);

      if (resValid === undefined) {
        setIsIncorrectResetCode(true);
        setResetCode("");
      } else {
        await localStorage.setItem("token", resValid?.data?.token ?? null);
        setIsLoading(false);
        props.onConfirmClick();
      }
    } catch (error) {
      setIsLoading(false); // Clear loading animation
      if (error === "Verification code is invalid!")
        setAlertModalOpen_ResetCodeError(true);
      else if (error === "Invalid email !") setAlertModalOpen_EmailError(true);
    }
  }

  async function handlePasswordRecoveryClick() {
    try {
      if (props.copyEmail === "") {
        setIsEmptyEmail(true);
        return false;
      } else {
        setIsEmptyEmail(false);

        const email = props.copyEmail;
        if (isValidEmail(email)) {
          setIsValid(false);
          setIsEmptyEmail(false);
        } else {
          setIsValid(true);
          return false;
        }
      }
      setIsLoading(true); // Show loading animation
      const res = await generateResetCode(props.copyEmail);

      if (res?.status) {
        setIsResetCodeFlag(true);
      } else {
        setIsResetCodeFlag(false);
      }

      setIsLoading(false);
    } catch (error) {
      // TODO Catches undefined error when invalid email is submitted?
      setIsLoading(false); // Clear loading animation
    }
  }

  return (
    <div className="login-form-content">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertModal
            isOpen={isIncorrectResetCode}
            onClose={() => {
              setIsIncorrectResetCode(false);
              setResetCode("");
            }}
            body={"Please enter valid reset code."}
            expCloseIcon={true}
          />

          <AlertModal
            isOpen={isResetCodeFlag}
            onClose={() => {
              setIsResetCodeFlag(false);
              setResetCode("");
            }}
            body={`Varification code has been resent to ${props?.copyEmail}`}
            expCloseIcon={true}
          />

          <form>
            <div className="input-container">
              <TextField
                variant="outlined"
                label="Reset Code"
                type="number"
                fullWidth
                value={resetCode}
                onChange={(event) => setResetCode(event.target.value)}
                error={isEmptyResetCode}
                helperText={isEmptyResetCode ? "This field is required." : ""}
              />
              <div
                style={{
                  textAlign: "end",
                  margin: "15px 0px",
                  fontSize: "14px",
                  color: BIBlue,
                  cursor: "pointer",
                }}
                onClick={handlePasswordRecoveryClick}
              >
                Didn't receive a code?
              </div>
            </div>
          </form>
          <div className="flex-container-horizontal">
            <div className="flex-container-left">
              <div className="input-container">
                <Button
                  size="small"
                  color="primary"
                  onClick={props.onBackClick}
                >
                  Back
                </Button>
              </div>
            </div>

            <div className="flex-container-right">
              <div className="input-container">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={resetCode != "" ? false : true}
                  onClick={handleResetPasswordClick}
                >
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function ConfirmPasswordForm(props) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [isEmptyResetCode, setIsEmptyResetCode] = useState(false);
  const [isEmptyPassword, setIsEmptyPassword] = useState(false);
  const [isEmptyRetypePassword, setIsEmptyRetypePassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [alertModalOpen_ResetCodeError, setAlertModalOpen_ResetCodeError] =
    useState(false);
  const [alertModalOpen_EmailError, setAlertModalOpen_EmailError] =
    useState(false);
  const [alertModalOpen_PasswordChange, setAlertModalOpen_PasswordChange] =
    useState(false);
  const [passwordStrengthCount, setPasswordStrengthCount] = React.useState(0);
  const [emailSent, setEmailSent] = useState(false);
  const [notifyToken, setNotifytoken] = useState("");

  const hash = (str) => {
    return sha256(str);
  };

  useEffect(async () => {
    const tok = await requestNotificationPermission();
    setNotifytoken(tok);
  }, []);

  async function handleResetPasswordClick() {
    const deviceName = await getDeviceId();
    try {
      if (password === "") setIsEmptyPassword(true);
      else setIsEmptyPassword(false);

      if (retypePassword === "") setIsEmptyRetypePassword(true);
      else setIsEmptyRetypePassword(false);

      // Check if passwords match
      if (password !== retypePassword)
        throw new Error("Passwords do not match!");

      setIsLoading(true); // Show loading animation

      const pwd = await hash(password);

      const newPasswordData = {
        Email: props.email,
        Password: pwd,
      };

      const token = localStorage.getItem("token");

      // Email was already checked in isValidResetCode
      const passResult = await saveNewPassword(newPasswordData, token);
      if (passResult?.status) {
        setAlertModalOpen_PasswordChange(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false); // Clear loading animation
      if (error === "Verification code is invalid!")
        setAlertModalOpen_ResetCodeError(true);
      else if (error === "Invalid email !") setAlertModalOpen_EmailError(true);
    }
  }

  const getPasswordStrength = () => {
    let strength = "";
    if (passwordStrengthCount === 0) strength = "";
    else if (passwordStrengthCount === 1) strength = "Weak";
    else if (passwordStrengthCount > 1 && passwordStrengthCount < 4)
      strength = "Medium";
    else if (passwordStrengthCount === 4) strength = "Strong";
    return strength;
  };

  async function gotoLoginPage() {
    const deviceId = await getDeviceId();
    const pwd = await hash(password);
    const loginObj = {
      DeviceId: deviceId,
      Email: props?.email,
      Password: pwd,
      AppName: "BuilID-Web",
      AppVersion: packageInfo?.version,
      Browser: bowserRes.parsedResult.browser?.name,
      BrowserVersion: bowserRes.parsedResult.browser?.version,
      Platform: bowserRes.parsedResult.os.name,
      PlatformVersion: "NA",
      DeviceName: "Browser",
      NotifyToken: notifyToken ?? null,
      LoginWith: "Password",
    };

    let loginResult = await userLogin(loginObj);
    const {
      isFirstTimeLoggedIn,
      UserId,
      DeviceId,
      Email,
      FirstName,
      LastName,
    } = loginResult?.data?.data?.userInformation;

    await localStorage.setItem(
      "token",
      loginResult?.data?.data?.accessToken ?? null
    );
    await localStorage.setItem("deviceId", DeviceId ?? null);
    await localStorage.setItem("userId", UserId);
    await localStorage.setItem("primaryEmail", Email);
    await localStorage.setItem("firstName", FirstName);
    await localStorage.setItem("lastName", LastName);
    await localStorage.setItem(
      "pictureUrl",
      loginResult?.data?.data?.pictureUrl ?? null
    );
    await localStorage.setItem("surveyLaterToday", "false");
    if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
      // const clientsWithLicense =
      //   loginResult?.data?.data?.userInformation?.ClientsList?.filter(
      //     (client) => Array.isArray(client.Licences)
      //   ) || [];
      let filteredData =
        loginResult?.data?.data.userInformation.ClientsList?.filter(
          (obj) => obj.OrganizationName !== "BI Free"
        );
      await localStorage.setItem(
        "clientList",
        JSON.stringify(filteredData)
      );
    }
    if (loginResult?.data?.data?.accessToken) {
      if (loginResult.data?.message === "OK") {
        if (isFirstTimeLoggedIn) {
          const tutorial = {
            dashboard: "true",
            sites: "true",
            montages: "true",
            ticket: "true",
            jobPostings: "true",
            carpool: "true",
            resumeBuilder: "true",
            contentSearch: "true",
          };
          localStorage.setItem("tutorial", JSON.stringify(tutorial));
          localStorage.setItem("newUser", "true");
          await localStorage.setItem("surveyLaterToday", "false");

          // const newPersonalInfo = new FormData();
          // newPersonalInfo.append("Id", 0);
          // newPersonalInfo.append("UserId", UserId);
          // newPersonalInfo.append("FirstName", firstName);
          // newPersonalInfo.append("LastName", lastName);
          // newPersonalInfo.append("RideShareInterested", false);
          // newPersonalInfo.append("UserEmail", props?.email);
          // newPersonalInfo.append("BackupEmail", "");
          // newPersonalInfo.append("NickName", "");
          // newPersonalInfo.append("EmploymentStatusId", 0);
          // newPersonalInfo.append("ModifiedBy", UserId);
          // newPersonalInfo.append("Birthday", "");
          // newPersonalInfo.append("PictureUrl", "");
          // console.log(
          //   "Populating new account with blank info...",
          //   JSON.stringify(newPersonalInfo)
          // );
          // await savePersonalInfo(newPersonalInfo);

          // let newUserAddress = {
          //   Id: 0,
          //   UserId: UserId,
          //   Address1: "",
          //   Address2: "",
          //   City: "",
          //   Province: "",
          //   PostalCode: "",
          //   Country: "",
          //   ModifiedBy: UserId,
          // };

          // console.log("Populating new account with blank info...");

          // await Promise.all([
          //   savePersonalInfo(newPersonalInfo),
          //   saveUserAddress(newUserAddress),
          // ]);
        }
      }
      window.location.replace("/dashboard");
      console.log(" window.location.replace");
    }
  }

  async function handlePasswordRecoveryClick() {
    try {
      if (props.email === "") {
        setIsEmptyEmail(true);
        return false;
      } else {
        setIsEmptyEmail(false);

        const email = props.email;
        if (isValidEmail(email)) {
          setIsValid(false);
          setIsEmptyEmail(false);
        } else {
          setIsValid(true);
          return false;
        }
      }
      setIsLoading(true); // Show loading animation
      const res = await generateResetCode(props.email);

      if (res === true) {
        setEmailSent(true);
      } else {
        setIsValid(true);
      }

      setIsLoading(false);
    } catch (error) {
      // TODO Catches undefined error when invalid email is submitted?
      setIsLoading(false); // Clear loading animation
    }
  }

  return (
    <div className="login-form-content">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertModal
            isOpen={alertModalOpen_PasswordChange}
            onClose={() => {
              setAlertModalOpen_PasswordChange(false);
              gotoLoginPage();
            }}
            title="Success"
            body="Password successfully changed!"
            expCloseIcon={true}
          />

          <form>
            <div className="input-container">
              <TextField
                type={showPassword ? "text" : "password"}
                variant="outlined"
                label="New Password"
                fullWidth
                defaultValue={password}
                error={isEmptyPassword}
                helperText={isEmptyPassword ? "This field is required." : ""}
                onChange={(e) => {
                  let count = 0;
                  if (e.target.value.length >= 6) count += 1;
                  if (e.target.value.match(/(?=.*\d)/)) count += 1;
                  if (e.target.value.match(/(?=.*[A-Z])/)) count += 1;
                  if (e.target.value.match(/(?=.*[a-z])/)) count += 1;
                  setPassword(e.target.value);
                  setPasswordStrengthCount(count);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1" // Unfocusable button
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <ShowIcon /> : <HideIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="flex-container-horizontal">
              <div className="flex-container-left">
                <div className="input-container">
                  <span>Password strength</span>
                </div>
              </div>
              <div className="flex-container-right">
                <div className="input-container">
                  <span
                    className="right"
                    style={{
                      color:
                        passwordStrengthCount === 1
                          ? BIRed
                          : passwordStrengthCount > 1 &&
                            passwordStrengthCount < 4
                            ? BIYellow
                            : passwordStrengthCount === 4
                              ? BIGreen
                              : BIGrey,
                    }}
                  >
                    {getPasswordStrength()}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "5px",
                borderRadius: "4px",
                backgroundColor: BIGrey,
              }}
            >
              <div
                style={{
                  borderRadius: "4px",
                  height: "5px",
                  width:
                    passwordStrengthCount === 1
                      ? "33%"
                      : passwordStrengthCount > 1 && passwordStrengthCount < 4
                        ? "66%"
                        : passwordStrengthCount === 4
                          ? "100%"
                          : 0,
                  backgroundColor:
                    passwordStrengthCount === 1
                      ? BIRed
                      : passwordStrengthCount > 1 && passwordStrengthCount < 4
                        ? BIYellow
                        : passwordStrengthCount === 4
                          ? BIGreen
                          : BIGrey,
                }}
              ></div>
            </div>

            <div className="input-container">
              <p
                style={{
                  color:
                    password !== ""
                      ? password.length >= 6
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}
              >
                {password === "" || password.length >= 6 ? (
                  <FaCheck
                    className="icon-position"
                    style={{ color: password.length >= 6 ? BIGreen : BIGrey }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 6 characters
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*\d)/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}
              >
                {password === "" || password.match(/(?=.*\d)/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*\d)/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 number
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*[A-Z])/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}
              >
                {password === "" || password.match(/(?=.*[A-Z])/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*[A-Z])/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 capital letter
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*[a-z])/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}
              >
                {password === "" || password.match(/(?=.*[a-z])/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*[a-z])/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 lowercase letter
              </p>
            </div>

            <div className="input-container">
              <TextField
                type={showRetypePassword ? "text" : "password"}
                variant="outlined"
                label="Retype New Password"
                fullWidth
                defaultValue={retypePassword}
                onChange={(event) => setRetypePassword(event.target.value)}
                error={isEmptyRetypePassword}
                helperText={
                  isEmptyRetypePassword ? "This field is required." : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1" // Unfocusable button
                        onClick={() =>
                          setShowRetypePassword(!showRetypePassword)
                        }
                      >
                        {showRetypePassword ? <ShowIcon /> : <HideIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="input-container">
              {retypePassword === "" || password === retypePassword ? (
                <p
                  style={{
                    color:
                      password === retypePassword && retypePassword !== ""
                        ? BIGreen
                        : BIGrey,
                  }}
                >
                  <FaCheck
                    className="icon-position"
                    style={{
                      color:
                        password === retypePassword && retypePassword !== ""
                          ? BIGreen
                          : BIGrey,
                    }}
                  />
                  Password
                </p>
              ) : (
                <p style={{ color: BIRed }}>
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                  Password
                </p>
              )}
            </div>
          </form>

          <div className="flex-container-horizontal">
            <div className="flex-container-left">
              <div className="input-container">
                <Button
                  size="small"
                  color="primary"
                  onClick={props.onBackClick}
                >
                  Back
                </Button>
              </div>
            </div>

            <div className="flex-container-right">
              <div className="input-container">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    passwordStrengthCount === 4 && password === retypePassword
                      ? false
                      : true
                  }
                  onClick={handleResetPasswordClick}
                >
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function CreateAccountForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [passwordStrengthCount, setPasswordStrengthCount] = React.useState(0);
  const [isValid, setIsValid] = useState(false);
  const [alertModalOpen_AccountExist, setAlertModalOpen_AccountExist] =
    useState(false);
  const [notifyToken, setNotifytoken] = useState("");

  const hash = (str) => {
    return sha256(str);
  };

  async function handleSignUpClick() {
    const deviceName = await getDeviceId();
    try {
      setIsLoading(true); // Show loading animation

      if (props.email === "") {
        setIsValid(false);
      } else {
        const email = props.email;
        if (isValidEmail(email)) {
          setIsValid(false);
        } else {
          setIsValid(true);
          setIsLoading(false);
          return false;
        }
      }

      // Check if passwords match
      if (password !== retypePassword)
        throw new Error("Passwords do not match!");

      // API create a new account
      const pass = await hash(password);

      let newUserId = -1;
      let newAccountData = {
        UserEmail: props.email,
        Password: pass,
        FirstName: firstName,
        LastName: lastName,
        // NotifyToken: notifyToken,
        // AppName: "BuilID-Web",
        // AppVersion: version,
        // Browser: bowserRes.parsedResult.browser?.name,
        // BrowserVersion: bowserRes.parsedResult.browser?.version,
        // Platform: bowserRes.parsedResult.os.name,
        // PlatformVersion: "NA",
        // DeviceName: deviceName
      };
      await createAccount(newAccountData).then((res) => (newUserId = res?.id));

      // // Create account was successful, redirect user to verification form to enter code
      if (newUserId > 0) {
        props.setAlertModalTitle("Account Created!");
        props.setAlertModalBody(
          "Please verify your email by entering the 6-digit code sent to your email."
        );
        props.goToVerifyForm();
      }
    } catch (error) {
      setIsLoading(false);
      setAlertModalOpen_AccountExist(true);
    }
  }

  const getPasswordStrength = () => {
    let strength = "";
    if (passwordStrengthCount === 0) strength = "";
    else if (passwordStrengthCount === 1) strength = "Weak";
    else if (passwordStrengthCount > 1 && passwordStrengthCount < 4)
      strength = "Medium";
    else if (passwordStrengthCount === 4) strength = "Strong";
    return strength;
  };

  useEffect(async () => {
    const tok = await requestNotificationPermission();
    setNotifytoken(tok);
  }, []);

  return (
    <div className="login-form-content">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertModal
            isOpen={alertModalOpen_AccountExist}
            onClose={() => setAlertModalOpen_AccountExist(false)}
            title="User already exists!"
            body="User already exists! Use 'Forgot password' option to reset your password!"
            expCloseIcon={true}
          />

          <h3>Create New Account</h3>

          <form>
            <div className="input-container">
              <TextField
                variant="outlined"
                label="Email"
                fullWidth
                defaultValue={props.email}
                onChange={props.handleEmailTextfieldChange}
                error={isValid}
                helperText={
                  isValid ? "Please enter a valid email address." : ""
                }
              />
            </div>
            <div className="input-container">
              <TextField
                variant="outlined"
                label="First Name"
                fullWidth
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                  localStorage.setItem("firstName", event.target.value);
                }}
              />
            </div>
            <div className="input-container">
              <TextField
                variant="outlined"
                label="Last Name"
                fullWidth
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                  localStorage.setItem("lastName", event.target.value);
                }}
              />
            </div>
            <div className="input-container">
              <TextField
                type={showPassword ? "text" : "password"}
                variant="outlined"
                label="Password"
                fullWidth
                defaultValue={password}
                onChange={(e) => {
                  let count = 0;
                  if (e.target.value.length >= 6) count += 1;
                  if (e.target.value.match(/(?=.*\d)/)) count += 1;
                  if (e.target.value.match(/(?=.*[A-Z])/)) count += 1;
                  if (e.target.value.match(/(?=.*[a-z])/)) count += 1;
                  setPassword(e.target.value);
                  setPasswordStrengthCount(count);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1" // Unfocusable button
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <ShowIcon /> : <HideIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="flex-container-horizontal">
              <div className="flex-container-left">
                <div className="input-container">
                  <span>Password strength</span>
                </div>
              </div>

              <div className="flex-container-right">
                <div className="input-container">
                  <span
                    className="right"
                    style={{
                      color:
                        passwordStrengthCount === 1
                          ? BIRed
                          : passwordStrengthCount > 1 &&
                            passwordStrengthCount < 4
                            ? BIYellow
                            : passwordStrengthCount === 4
                              ? BIGreen
                              : BIGrey,
                    }}
                  >
                    {getPasswordStrength()}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "5px",
                borderRadius: "4px",
                backgroundColor: BIGrey,
              }}
            >
              <div
                style={{
                  borderRadius: "4px",
                  height: "5px",
                  width:
                    passwordStrengthCount === 1
                      ? "33%"
                      : passwordStrengthCount > 1 && passwordStrengthCount < 4
                        ? "66%"
                        : passwordStrengthCount === 4
                          ? "100%"
                          : 0,
                  backgroundColor:
                    passwordStrengthCount === 1
                      ? BIRed
                      : passwordStrengthCount > 1 && passwordStrengthCount < 4
                        ? BIYellow
                        : passwordStrengthCount === 4
                          ? BIGreen
                          : BIGrey,
                }}
              ></div>
            </div>

            <div className="input-container">
              <p
                style={{
                  color:
                    password !== ""
                      ? password.length >= 6
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}
              >
                {password === "" || password.length >= 6 ? (
                  <FaCheck
                    className="icon-position"
                    style={{ color: password.length >= 6 ? BIGreen : BIGrey }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 6 characters
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*\d)/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}
              >
                {password === "" || password.match(/(?=.*\d)/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*\d)/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 number
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*[A-Z])/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}
              >
                {password === "" || password.match(/(?=.*[A-Z])/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*[A-Z])/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 capital letter
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*[a-z])/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}
              >
                {password === "" || password.match(/(?=.*[a-z])/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*[a-z])/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 lowercase letter
              </p>
            </div>

            <div className="input-container">
              <TextField
                type={showRetypePassword ? "text" : "password"}
                variant="outlined"
                label="Retype Password"
                fullWidth
                defaultValue={retypePassword}
                onChange={(event) => setRetypePassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1" // Unfocusable button
                        onClick={() =>
                          setShowRetypePassword(!showRetypePassword)
                        }
                      >
                        {showRetypePassword ? <ShowIcon /> : <HideIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="input-container">
              {retypePassword === "" || password === retypePassword ? (
                <p
                  style={{
                    color:
                      password === retypePassword && retypePassword !== ""
                        ? BIGreen
                        : BIGrey,
                  }}
                >
                  <FaCheck
                    className="icon-position"
                    style={{
                      color:
                        password === retypePassword && retypePassword !== ""
                          ? BIGreen
                          : BIGrey,
                    }}
                  />{" "}
                  Password match
                </p>
              ) : (
                <p style={{ color: BIRed }}>
                  <FaTimes className="icon-position" style={{ color: BIRed }} />{" "}
                  Password match
                </p>
              )}
            </div>
          </form>

          <div
            className="flex-container-horizontal"
            style={{ justifyContent: "space-between" }}
          >
            <div className="input-container">
              <Button size="small" color="primary" onClick={props.onBackClick}>
                Back
              </Button>
            </div>

            <div className="input-container">
              <Button
                variant="contained"
                color="primary"
                disabled={
                  props.email !== "" &&
                    firstName !== "" &&
                    lastName !== "" &&
                    passwordStrengthCount === 4 &&
                    password === retypePassword
                    ? false
                    : true
                }
                onClick={handleSignUpClick}
              >
                Create Account
              </Button>
            </div>
          </div>

          {/* <div className="input-container">
								<Button
									size="small"
									color="primary"
									onClick={props.goToVerifyForm}>
									I have a verification code
								</Button>
							</div> */}
        </>
      )}
    </div>
  );
}

function VerifyEmailForm(props) {
  const [verificationCode, setVerificationCode] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [isEmptyVerificationCode, setIsEmptyVerificationCode] = useState(false);
  const [notifyToken, setNotifytoken] = useState("");

  async function handleVerifyClick() {
    const deviceName = await getDeviceId();
    if (props.email === "") {
      setIsEmptyEmail(true);
      setIsValid(false);
      verificationCode === ""
        ? setIsEmptyVerificationCode(true)
        : setIsEmptyVerificationCode(false);
      return false;
    } else {
      setIsEmptyEmail(false);
      verificationCode === ""
        ? setIsEmptyVerificationCode(true)
        : setIsEmptyVerificationCode(false);

      const email = props.email;
      if (isValidEmail(email)) {
        setIsValid(false);
        setIsEmptyEmail(false);
      } else {
        setIsValid(true);
        return false;
      }
    }

    if (verificationCode === "") {
      setIsEmptyVerificationCode(true);
    } else {
      setIsEmptyVerificationCode(false);

      const obj = JSON.stringify({
        email: props.email,
        resetCode: verificationCode,
        NotifyToken: notifyToken ?? null,
        AppName: "BuilID-Web",
        AppVersion: packageInfo?.version,
        Browser: bowserRes.parsedResult.browser?.name,
        BrowserVersion: bowserRes.parsedResult.browser?.version,
        Platform: bowserRes.parsedResult.os.name,
        PlatformVersion: "NA",
        DeviceName: deviceName,
        SendWelcomeEmail: true,
      });

      // It's not a "reset" code, but this is the API for it
      isValidResetCode(obj)
        .then(async (res) => {
          if (
            res === "Verification code is invalid!" ||
            res === "Not a valid Email address!" ||
            res === "Invalid email !"
          )
            props.setAlertModalTitle("Invalid verification code!");
          else {
            await localStorage.setItem("token", res);
            props.setAlertModalTitle("Your account has been verified!");
            props.setAlertModalBody("Please log in.");
            props.goToLoginForm();
          }
        })
        .catch((err) =>
          err === "Invalid email !"
            ? props.setAlertModalTitle("Invalid email !")
            : props.setAlertModalTitle("Invalid verification code!")
        );
    }
  }

  useEffect(async () => {
    const tok = await requestNotificationPermission();
    setNotifytoken(tok);
  }, []);

  return (
    <div className="login-form-content">
      <h3>Verify Email</h3>
      <form>
        <div className="input-container">
          <TextField
            variant="outlined"
            label="Email"
            fullWidth
            defaultValue={props.email}
            onChange={props.handleEmailTextfieldChange}
            error={isEmptyEmail || isValid}
            helperText={
              isEmptyEmail
                ? "This field is required."
                : isValid
                  ? "Please enter a valid email address."
                  : ""
            }
          />
        </div>
        <div className="input-container" style={{ paddingBottom: "30px" }}>
          <TextField
            variant="outlined"
            label="Verification Code"
            fullWidth
            defaultValue={verificationCode}
            error={isEmptyVerificationCode}
            helperText={
              isEmptyVerificationCode ? "This field is required." : ""
            }
            onChange={(event) => setVerificationCode(event.target.value)}
          />
        </div>
      </form>

      <div
        className="flex-container-horizontal"
        style={{ justifyContent: "space-between" }}
      >
        <div className="input-container">
          <Button size="small" color="primary" onClick={props.onBackClick}>
            Back
          </Button>
        </div>
        <div className="input-container">
          <Button
            variant="contained"
            color="primary"
            onClick={handleVerifyClick}
          >
            Verify
          </Button>
        </div>
      </div>
    </div>
  );
}
