import { Button, Modal, Select, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BuildIDLogo from "../../assets/images/Build-ID-Transparent.png";
import { Group, InsertDriveFile, LocationOn } from "@material-ui/icons";
import NextModal from "./NextModal";
import {
  ClientSafetyTicketsApi,
  GetClientSafetyTickets,
} from "../../api/ClientApi";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SupportIcon from "@mui/icons-material/Support";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ChatIcon from "@mui/icons-material/Chat";
import ImageIcon from "@mui/icons-material/Image";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import PlaceIcon from "@mui/icons-material/Place";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import ListIcon from "@mui/icons-material/List";
import { BIBlue } from "../../assets/buildidColors";


export default function SafetyTicketSharingModal({
  open,
  onActionSuccesOfThermalSystems,
  clientId,
}) {
  const [nextModalOpen, setNextModalOpen] = useState(false);
  const [clientDetails, setClientDetails] = useState("");

  useEffect(() => {
    getClientData();
  }, []);

  const getClientData = async () => {
    try {
      const res = await GetClientSafetyTickets(clientId);
      setClientDetails(res[0]);
    } catch (error) {}
  };

  const handleNextClick = () => {
    setNextModalOpen(true);
  };

  const handleCloseNextModal = () => {
    setNextModalOpen(false);
  };

  const onDeclineClick = async () => {
    onActionSuccesOfThermalSystems("Decline");
  };


  console.log("clientDetails===>",clientDetails)

  const getAppIcon = (appName) => {
    switch (appName) {
      case "Expenses":
        return <StickyNote2Icon style={{ color: BIBlue }} fontSize="large"/>;
      case "Timesheet":
        return <CalendarMonthIcon style={{ color: BIBlue }} fontSize="large"/>;
      case "Safety Observation":
        return <SupportIcon style={{ color: BIBlue }} fontSize="large"/>;
      case "Safety Incident":
        return <AddBoxIcon style={{ color: BIBlue }} fontSize="large"/>;
      case "Coaching":
        return <ChatIcon style={{ color: BIBlue }} fontSize="large"/>;
      case "Picture Board":
        return <ImageIcon style={{ color: BIBlue }} fontSize="large"/>;
      case "Change Tracker":
        return <StickyNote2Icon style={{ color: BIBlue }} fontSize="large"/>;
      case "Communicator":
        return <ChatIcon style={{ color: BIBlue }} fontSize="large"/>;
      case "Site Estimation":
        return <ChatIcon style={{ color: BIBlue }} fontSize="large"/>;
      case "Support":
        return <LiveHelpIcon style={{ color: BIBlue }} fontSize="large"/>;
      case "Site Activity Reports":
        return <PlaceIcon style={{ color: BIBlue }} fontSize="large"/>;
      case "Actions":
        return <ListIcon style={{ color: BIBlue }} fontSize="large"/>;
      case "Site Filing":
        return <ListIcon style={{ color: BIBlue }} fontSize="large"/>;
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-center" style={{ width: "450px" }}>
          <div
            style={{
              padding: "20px",
            }}
            className="modal-card"
          >
            <img
              src={clientDetails?.clientLogo} // Replace with your actual logo path
              alt="Thermal Systems Logo"
              className="modal-logo"
            />

            <>
              <Typography
                variant="h6"
                className="modal-title"
                style={{ textAlign: "center" }}
              >
                <b>{clientDetails?.OrganizationName}</b> is inviting you to join their
                Company on BuildID.
              </Typography>

              {/* Feature List */}
              {clientDetails?.appLicensesList?.map((item, index) => {
                return (
                  <div className="feature-item" key={index}>
                    <div className="feature-icon">
                      {getAppIcon(item?.appName)}
                    </div>
                    <div>
                      <Typography variant="h6">
                        <b>{item?.appName}</b>
                      </Typography>
                      <Typography variant="body2">{item?.features}</Typography>
                    </div>
                  </div>
                );
              })}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    width: "150px",
                    height: "40px",
                  }}
                  onClick={handleNextClick}
                >
                  NEXT
                </Button>
                <Button
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    width: "150px",
                    height: "40px",
                  }}
                  onClick={onDeclineClick}
                >
                  DECLINE
                </Button>
              </div>
            </>
          </div>
        </div>
      </Modal>
      <NextModal
        open={nextModalOpen}
        onClose={handleCloseNextModal}
        onActionSuccesOfThermalSystems={(value) => {
          onActionSuccesOfThermalSystems(value);
        }}
        clientDetails={clientDetails}
      />
    </>
  );
}
