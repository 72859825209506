import { restGet, restPost } from "./RestClient";
import { headers, form_headers } from "./UserApi";

// let headers = {
//  "Content-Type": "application/json",
//  "Accept": "application/json",
//  "Authorization": ('Bearer ' + localStorage.getItem('token')),
// }

// let form_headers = {
//  "Content-Type": "multipart/form-data",
//  "Accept": "application/json",
//  "Authorization": ('Bearer ' + localStorage.getItem('token')),
// }

const googleApiKey = 'AIzaSyDFLELJHL-t3S5DkTgHRPWVBNpg8uW76Kc';

export async function fetchSiteCompanies2(args) {
	const res = await restPost("sites/FetchSiteCompanies2/", args, headers);
	return res;
}

// Adds/edits a company (and/or role) to a project
export async function saveSiteCompany(args) {
	const res = restPost("sites/SaveSiteCompany", args, headers);
	return res;
}


export async function saveSiteCompanyRoles(args) {
	const res = restPost("sites/SaveSiteCompanyRoles", args, headers);
	return res;
}

export async function saveUserSitesProjects(args) {
	const res = restPost("sites/SaveUserSitesProjects", args, headers);
	return res;
}

// Get comapny role options for Dropdown
export async function fetchSiteCompanyRoles(args) {
	const res = await restPost("sites/fetchSiteCompanyRoles/", args, headers);
	return res.data;
}

export async function fetchFollowEntities(args) {
	const res = await restPost("misc/fetchFollowEntities/", args, headers);
	return res;
}
export async function saveFollowEntities(args) {
	const res = await restPost("misc/saveFollowEntities", args, headers);
	return res.data;
}

export async function fetchSites(args) {
	const res = await restPost("sites/fetchSites/", args, headers);
	return res;
}

export async function fetchSitesActive(args) {
	const res = await restPost("Sites/FetchSitesActive", args, headers);
	return res;
}



export async function fetchSitesForWeb(args) {
	const res = await restPost("sites/fetchSitesForWeb/", args, headers);
	return res;
}


export async function fetchSitesPictures(args) {
	const res = await restPost("sites/FetchSitesPictures/", args, headers);
	return res.data;
}
export async function FetchSitesPostsPictures(args) {
	const res = await restPost("sites/FetchSitesPostsPictures", args, headers);
	return res.data;
}

export async function fetchSitesPostsPicturesForWeb(args) {
	const res = await restPost("sites/FetchSitesPostsPicturesForWeb", args);
	return res.data;
}


export async function saveSitesPictures(args) {
	const res = await restPost("sites/SaveSitesPictures/", args, form_headers);
	return res.data;
}

export async function saveSitesDetails(args) {
	const res = await restPost("sites/SaveSites", args, headers);
	return res.data;
}

export async function fetchSiteTypes(args) {
	const res = await restPost("sites/FetchSitesTypes/", args, headers);
	return res;
}

export async function fetchSiteNames(args) {
	const res = await restPost("sites/FetchSiteNames/", args, headers);
	return res;
}
export async function fetchSiteNamesForWeb(args) {
	const res = await restPost("sites/FetchSiteNamesForWeb/", args);
	return res;
}
export async function SaveSiteNames(args) {
	const res = await restPost("sites/SaveSiteNames/", args, headers);
	return res;
}


export async function fetchSiteProducts(args) {
	const res = await restPost("sites/fetchSiteProducts/", args, headers);
	return res;
}

export async function saveSiteProducts(args) {
	const res = await restPost("sites/saveSiteProducts/", args, headers);
	return res.data;
}

export async function saveSitePosts(args) {
	const res = await restPost("posts/saveposts/", args, headers);
	return res.data;
}

export async function saveSitePostPicture(args) {
	const res = await restPost("posts/savepostpicture/", args, form_headers);
	return res.data;
}


export async function getSitesForProduct(productId) {
	const res = await restGet("sites/getSitesForProduct/" + localStorage.userId + "/" + productId, headers);
	return res;
}

export async function getCommonSites(contactId) {
	const res = await restGet("sites/getCommonSites/" + localStorage.userId + "/" + contactId, headers);
	return res;
}

export async function getCommonProjects(contactId) {
	const res = await restGet("Projects/GetCommonProjects/" + localStorage.userId + "/" + contactId, headers);
	return res;
}

export async function getProjectCompanyRoles() {
	const res = await restGet("Projects/getProjectCompanyRoles/0/0/0", headers);
	return res;
}

// Gets all projects within latitude/longitude bounds
export async function getSitesByMapBounds2(activeOnly, sLat, nLat, wLng, eLng) {
	const res = await restGet("sites/getSitesByMapBounds2/" + activeOnly.toString() + "/" + sLat + "/" + nLat + "/" + wLng + "/" + eLng + "/", headers);
	return res;
}

export async function getSitesByMapBounds(args) {
	const res = await restPost("sites/getSitesByMapBounds/", args, headers);
	return res.data;
}

export async function fetchNearestSitesList(args) {
	const res = await restPost("Sites/FetchNearestSiteDetails/", args, headers);
	return res.data;
}

export async function saveOnBoardingUserExperience(args) {
	const res = await restPost("Sites/SaveOnBoardingUserExperience/", args, headers);
	return res.data;
}

export async function siteActiveClaims(args) {
	const res = await restPost("sites/SiteActiveClaims/", args, headers);
	return res.data;
}

export async function getAddress(coordinate) {
    const wrapLongitude = (lng) => {
        return ((((lng + 180) % 360) + 360) % 360) - 180; // Wraps longitude to [-180, 180]
    };

    const wrapLatitude = (lat) => {
        return Math.max(-90, Math.min(90, lat)); // Clamps latitude to [-90, 90]
    };

    return new Promise(function (resolve, reject) {
        let objAddress = {
            fullAddress: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            country: '',
            latitude: 0,
            longitude: 0
        };
        let objRet = {
            objAddress: objAddress,
            message: ''
        };

        // Use the wrapping functions for latitude and longitude
        const fixedLatitude = wrapLatitude(coordinate?.latitude);
        const fixedLongitude = wrapLongitude(coordinate?.longitude);

        if (coordinate === null || fixedLatitude === null || fixedLongitude === null) {
            objRet.message = "Something wrong with GPS coordinates!";
            resolve(objRet);
        }

        fetch(`https://maps.google.com/maps/api/geocode/json?latlng=${fixedLatitude},${fixedLongitude}&sensor=true&key=${googleApiKey}`, {
            method: 'GET',
            compress: true,
            headers: {
                'Accept': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.status !== 'OK' && json.status !== 'ZERO_RESULTS') {
                    objRet.message = JSON.stringify(json.status);
                    resolve(objRet);
                }

                let addressString = '', address = '', city = '', province = '', country = '', postalcode = '';
                objAddress.latitude = fixedLatitude;
                objAddress.longitude = fixedLongitude;

                if (json.results.length > 0) {
                    if (json.results[0].address_components.length > 0) {
                        for (let i = 0; i < json.results[0].address_components.length; i++) {
                            let x = json.results[0].address_components[i];
                            if (x.types[0] === "street_number") address += x.long_name + " ";
                            if (x.types[0] === "route") address += x.long_name + " ";
                            if (x.types[0] === "locality" || x.types[0] === "postal_town") city = x.long_name;
                            if (x.types[0] === "administrative_area_level_1") province = x.short_name;
                            if (x.types[0] === "country") country = x.long_name;
                            if (x.types[0] === "postal_code" || x.types[1] === "postal_code") postalcode = x.long_name;
                        }
                        addressString = json.results[0].formatted_address;
                        objAddress = {
                            address: address,
                            city: city,
                            province: province,
                            country: country,
                            postalCode: postalcode,
                            latitude: fixedLatitude,
                            longitude: fixedLongitude,
                            fullAddress: addressString
                        };
                        objRet.objAddress = objAddress;
                    }
                }
                resolve(objRet);
            })
            .catch((error) => {
                reject({
                    statusCode: 403,
                    message: "Server not responding! " + error.message
                });
            });
    });
}

export async function getSiteMembers(projectId) {
	const res = await restGet("sites/getSiteMembers/" + localStorage.userId + "/" + projectId, headers);
	return res;
}

export async function getSiteDetails(siteId) {
	const res = await restGet("Sites/GetSitesDetailsForWeb/" + siteId, headers);
	return res;
}

export async function fetchFollowingSites(args) {
	const res = await restPost("sites/FetchFollowingSites", args, headers);
	return res;
}

export async function fetchSitesCountry(args) {
	const res = await restPost("misc/FetchCountries", args, headers);
	return res.data;
}

export async function saveSitesFavouriteImages(args) {
	const res = await restPost("sites/SaveSitesFavouriteImages", args, headers);
	return res.data;
}
export async function deleteSitesFavouriteImages(args) {
	const res = await restPost("sites/DeleteSitesFavouriteImages", args, headers);
	return res.data;
}
export async function fetchSitesFavouriteImages(args) {
	const res = await restPost("sites/FetchSitesFavouriteImages", args, headers);
	return res.data;
}

export async function fetchUsersJobPosts(args) {
	const res = await restPost("sites/FetchUsersJobPosts", args, headers);
	return res.data;
}
export async function fetchJobPosts(args) {
	const res = await restPost("Sites/FetchJobPosts", args, headers);
	return res.data;
}

export async function getExpiredJobPosts(uid) {
	const res = await restGet("sites/GetExpiredJobPosts/" + uid, headers);
	return res;
}

export async function fetchUsersJobPostsDistance(args) {
	const res = await restPost(
		"sites/FetchUsersJobPostsDistance/",
		args,
		headers
	);
	return res;
}

export async function updateExpiredJobPosts(args) {
	const res = await restPost("sites/UpdateExpiredJobPosts/", args, headers);
	return res;
}
export async function deleteJobPosts(args) {
	const res = await restPost("sites/deleteJobPosts", args, headers);
	return res.data;
}
export async function saveJobPosts(args) {
	const res = await restPost("Sites/SaveJobPosts/", args, headers);
	return res.data;
}
export async function saveUsersJobPosts(args) {
	const res = await restPost("Sites/SaveUsersJobPosts/", args, headers);
	return res.data;
}
export async function sendUserResume(args) {
	const res = await restPost("users/SendUserResume", args, headers);
	return res.data;
}

export async function SiteIssues(userId) {
	const res = await restGet("sites/SiteIssues/" + userId, headers);
	return res;
}

export async function fetchSitesDescriptions(args) {
	const res = await restPost("sites/FetchSitesDescriptions", args, headers);
	return res;
}

export async function saveFollowingProjects(args) {
	const res = await restPost("Sites/SaveFollowingProjects", args, headers);
	return res;
}

export async function fetchSitesProjects(args) {
	const res = await restPost("sites/FetchSitesProjects", args, headers);
	return res;
}

export async function saveSitesDescriptions(args) {
	const res = await restPost("sites/saveSitesDescriptions/", args, headers);
	return res;
}

export async function siteDetailsByType(args) {
	const res = await restPost("sites/SiteDetailsByType", args, headers);
	return res.data;
}

export async function SaveSites(args) {
	const res = await restPost("sites/SaveSites", args, headers);
	return res;
}

export async function fetchProducts(args) {
	const res = await restPost("misc/fetchProducts", args, headers);
	return res;
}


export async function saveProjectCompany(args) {
	const res = await restPost("Projects/SaveProjectCompanies", args, headers);
	return res;
}

export async function saveUsersExperiencesProducts(args) {
	const res = await restPost("Sites/SaveUsersExperiencesProducts", args, headers);
	return res;
}

//changes-07-03-24-rahul
export async function deleteUserWorkHistories2(args) {
	const res = await restPost("users/DeleteUserWorkHistories/", args, headers);
	return res;
}
export async function deleteUsersWorkHistory(args) {
	const res = await restPost("users/DeleteUsersWorkHistory/", args, headers);
	return res;
}

export async function deleteUserSitesProjects(args) {
	const res = await restPost("Sites/DeleteUserSitesProjects/", args, headers);
	return res;
}

export async function fetchProjectCompanies2(args) {
	const res = await restPost("Projects/FetchProjectCompanies2", args, headers);
	return res;
}

export async function deleteUsersExperiencesProducts(args) {
	const res = await restPost("Sites/DeleteUsersExperiencesProducts", args, headers);
	return res;
}


export async function deleteSites(args) {
	const res = await restPost("sites/DeleteSites", args, headers);
	return res;
}