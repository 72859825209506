import { Close } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { BIBrown, BIGreen, BIGrey, BILightGreen, BILightOrange, BILightYellow } from "../../../assets/buildidColors";

export default function ClientChangeStatusModal(props) {
  return (
    <div>
      <Modal open={props?.open} onClose={props?.close}>
        <div
          className="modal-center"
          style={{ border: "none", outline: "none" }}
        >
          <div className="modal-card" style={{ height: "auto", width: "100%" }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Grid
                  gap={1}
                  container
                  justifyContent="space-between"
                  alignItems={"center"}
                  paddingTop={2}
                  paddingBottom={0}
                  paddingInline={2}
                >
                  <Grid item>
                    <Typography variant="body1">Update Status</Typography>
                  </Grid>
                  <IconButton
                    color="primary"
                    onClick={props?.close}
                    style={{ padding: "0px" }}
                  >
                    <Close />
                  </IconButton>
                </Grid>
                <CardContent>
                  <Grid container alignItems="center">
                    <Grid item>
                      <FormControl>
                        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue={props.selectedStatus} name="radio-buttons-group">
                          <div
                            style={{
                              border: "1px solid black",
                              marginBottom: "12px",
                              paddingRight: "10rem",
                              borderRadius: "4px",
                              paddingLeft: "1rem",
                            }}
                          >
                            <FormControlLabel
                              onClick={() => {
                                props.saveStatus(1);
                              }}
                              value="In progress"
                              control={<Radio />}
                              label={
                                <Chip
                                  label="In progress"
                                  style={{
                                    backgroundColor: BILightYellow,
                                    color: BIGrey,
                                  }}
                                />
                              }
                            />
                          </div>
                          <div
                            style={{
                              border: "1px solid black",
                              marginBottom: "12px",
                              paddingRight: "5rem",
                              borderRadius: "4px",
                              paddingLeft: "1rem",
                            }}
                          >
                            <FormControlLabel
                              onClick={() => {
                                props.saveStatus(2);
                              }}
                              value="On Hold"
                              control={<Radio />}
                              label={
                                <Chip
                                  label="On Hold"
                                  style={{
                                    backgroundColor: "pink",
                                    color: "brown",
                                  }}
                                />
                              }
                            />
                          </div>
                          <div
                            style={{
                              border: "1px solid black",
                              marginBottom: "12px",
                              paddingRight: "5rem",
                              borderRadius: "4px",
                              paddingLeft: "1rem",
                            }}
                          >
                            <FormControlLabel
                              onClick={() => {
                                props.saveStatus(3);
                              }}
                              value="Completed"
                              control={<Radio />}
                              label={
                                <Chip
                                  label="Completed"
                                  style={{
                                    backgroundColor: BILightGreen,
                                    color: BIGreen,
                                  }}
                                />
                              }
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
}