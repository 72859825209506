import React from "react";
import { ClientHeader } from "../ClientHeader";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, LinearProgress, linearProgressClasses, Stack, styled, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function ClientQualityControl() {
  const nav = useHistory();

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#1a90ff",
      ...theme.applyStyles("dark", {
        backgroundColor: "#308fe8",
      }),
    },
  }));

  const handelNavigate = (id) => {
    nav.push(`/client-quality-control/details/${id}`);
  };
  return (
    <>
      <ClientHeader screenName={"Quality Control"} />

      <div className="page">
        <div className="page-content" style={{ marginTop: "20px" }}>
          <Grid2 container direction="row" size={12} spacing={2}>
            {[...Array(8)].map((item, index) => (
              <Grid2 item xs={6} sm={6} md={4} lg={3} xl={3} key={index}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardActionArea
                    onClick={() => {
                      handelNavigate(index);
                    }}
                  >
                    <CardContent
                      style={{
                        padding: "10px",
                        maxHeight: "250px",
                        minHeight: "250px",
                      }}
                    >
                      <Stack display={"flex"} height={230} justifyContent={"space-between"}>
                        <Stack>
                          <Typography gutterBottom variant="subtitle2" fontSize={17} component="div" fontWeight={"bold"} color={"grey"}>
                            Astra Place 800 Cladding
                          </Typography>
                          <Typography gutterBottom variant="subtitle1" component="div" color={"grey"}>
                            C24-00581
                          </Typography>
                        </Stack>
                        <Stack mb={2}>
                          <Typography gutterBottom variant="subtitle2" fontSize={17} component="div" fontWeight={"bold"} color={"grey"}>
                            Progress
                          </Typography>
                          <BorderLinearProgress variant="determinate" value={50} />
                        </Stack>
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        </div>
      </div>
    </>
  );
}
