import React, { useEffect, useRef, useState } from "react";
import { IconButton, Modal } from "@mui/material";
import { Close } from "@material-ui/icons";
import moment from "moment";

import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import { fetchClientTake5Recordings } from "../../../api/ClientApi";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/Loader";

export default function ClientTakeFiveViewModel({ open, onClose, jobData, take5id }) {
    const [recordingData, setRecordingData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [pageNO, setPageNO] = useState(1);

    useEffect(() => {
        if (open) fetchRecordings();
    }, [open]);

    const fetchRecordings = async () => {
        let obj = {
            PageNr: pageNO,
            NrOfRecPerPage: 20,
            FullSearch: "",
            UserId: Number(localStorage.getItem("userId")),
            IncludeRecordNr: true,
            FetchAllowedRecordsOnly: true,
            SearchList: [
                {
                    clientTake5Id: take5id,
                },
            ],
        };

        let res = await fetchClientTake5Recordings(obj);

        if (res.status === true) {
            if (res.data.length > 0) {
                setHasMore(true);
                setPageNO((prev) => prev + 1);

                let temp = [];
                res?.data?.map((data) => {
                    temp.push({
                        ...data,
                        isPlaying: false,
                    });
                });

                setRecordingData((prev) => [...prev, ...temp]);
            } else {
                setHasMore(false);
            }
        }
    };

    const handelClose = () => {
        onClose();
        setRecordingData([]);
        setHasMore(true);
        setPageNO(1);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handelClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal-center">
                    <div
                        style={{
                            padding: "20px",
                            width: "500px",
                        }}
                        className="modal-card"
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <IconButton
                                color="primary"
                                onClick={handelClose}
                                style={{ padding: "0px" }}
                            >
                                <Close />
                            </IconButton>
                        </div>

                        <InfiniteScroll
                            dataLength={recordingData?.length} //This is important field to render the next data
                            next={fetchRecordings}
                            hasMore={hasMore}
                            loader={<Loader />}
                            height={400}
                            style={{
                                overflow: "auto",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {recordingData.map((item, index) => (
                                <ClientTakeFiveAudio
                                    key={index}
                                    data={item}
                                    setRecordingData={setRecordingData}
                                />
                            ))}
                        </InfiniteScroll>
                    </div>
                </div>
            </Modal>
        </>
    );
}

const ClientTakeFiveAudio = ({ data, setRecordingData }) => {
    const [toggle, setToggle] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);

    useEffect(() => {
        if (!data.isPlaying) {
            setToggle(false);
            audioRef.current.currentTime = 0;
            audioRef.current.pause();
            setCurrentTime(0);
        }
    }, [data]);

    useEffect(() => {
        if (currentTime === duration + 1) {
            setToggle(false);
            setCurrentTime(0);
            audioRef.current.currentTime = 0;
        }
    }, [currentTime]);

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
        if (audioRef.current.currentTime === audioRef.current.duration) {
            setToggle(false);
        }
    };

    const handleLoadedMetadata = (e) => {
        setDuration(audioRef.current.duration);
    };

    const handleSeek = (event) => {
        audioRef.current.currentTime = event.target.value;
        setCurrentTime(event.target.value);
    };

    const handleTogglePlay = () => {
        if (toggle) {
            audioRef.current.pause();
            setRecordingData((prev) => prev.map((item) => ({ ...item, isPlaying: false })));
        } else {
            audioRef.current.play();
            setRecordingData((prev) =>
                prev.map((item) =>
                    item.id === data.id
                        ? { ...item, isPlaying: true }
                        : { ...item, isPlaying: false }
                )
            );
        }

        setToggle(!toggle);
    };

    return (
        <>
            <div
                style={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "10px",
                }}
            >
                <div>{moment(data.dateCreated).format("MMMM DD, YYYY")}</div>
                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "row",
                            width: "100%",
                        }}
                    >
                        <span
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexGrow: 1,
                                marginRight: "10px",
                            }}
                            onClick={() => {
                                handleTogglePlay();
                            }}
                        >
                            {!toggle ? (
                                <PlayArrowRoundedIcon fontSize="large" />
                            ) : (
                                <PauseRoundedIcon fontSize="large" />
                            )}
                        </span>
                        <input
                            style={{
                                flexGrow: 3,
                                width: "100%",
                            }}
                            type="range"
                            max={duration}
                            value={currentTime}
                            onChange={handleSeek}
                        />
                        <span
                            style={{
                                flexGrow: 1,
                                marginLeft: "10px",
                            }}
                        >
                            {moment.utc(duration * 1000).format("m:ss")}
                        </span>
                    </div>
                </div>

                <audio
                    ref={audioRef}
                    src={data?.fileUrl}
                    type="audio/mp3"
                    hidden
                    controls
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleLoadedMetadata}
                    name="media"
                ></audio>
            </div>
        </>
    );
};
