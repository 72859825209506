import React, { useState } from "react";
import { ClientHeader } from "../ClientHeader";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    LinearProgress,
    linearProgressClasses,
    Radio,
    RadioGroup,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import { ClientQualityControlModel } from "./ClientQualityControlModel";

export default function ClientQualityControlDetails() {
    const [modelOpen, setModelOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("a");

    const ClickableText = styled(Typography)(({ theme }) => ({
        "cursor": "pointer",
        "transition": " color 0.1s ease",
        "&:hover": {
            // Change color on hover
        },

        "userSelect": "none", // Prevent text selection
        "&:active": {
            color: "lightgrey",
        },
    }));

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[200],
            ...theme.applyStyles("dark", {
                backgroundColor: theme.palette.grey[800],
            }),
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: "#1a90ff",
            ...theme.applyStyles("dark", {
                backgroundColor: "#308fe8",
            }),
        },
    }));
    return (
        <>
            <ClientHeader screenName={"Quality Control"} />
            <div className="page">
                <div className="page-content" style={{ marginTop: "20px" }}>
                    <Card
                        sx={{
                            width: "100%",
                            padding: "20px",
                        }}
                    >
                        <Grid2 xl={12} lg={12} md={12} sm={12} xs={12} container>
                            <Grid2 item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Typography fontSize={17} variant="subtitle1" fontWeight={"bold"}>
                                    LC - Honewell - Captain Nichola Goddard School
                                </Typography>
                            </Grid2>
                            <Grid2 mb={2} item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        color: "grey",
                                    }}
                                >
                                    BM25-01100
                                </Typography>
                            </Grid2>
                            <Grid2 item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        color: "grey",
                                    }}
                                >
                                    LC - Honewell - Captain Nichola Goddard School
                                </Typography>
                            </Grid2>
                        </Grid2>
                        <Divider
                            sx={{
                                margin: "10px 0px",
                            }}
                        />
                        <Grid2 container>
                            <Grid2 item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Stack
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    flexDirection={"row"}
                                >
                                    <Typography
                                        gutterBottom
                                        variant="subtitle2"
                                        fontSize={17}
                                        component="div"
                                        fontWeight={"bold"}
                                        color={"grey"}
                                    >
                                        Progress
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="subtitle2"
                                        fontSize={17}
                                        component="div"
                                        fontWeight={"bold"}
                                        color={"grey"}
                                    >
                                        37%
                                    </Typography>
                                </Stack>
                            </Grid2>
                            <Grid2 mb={10} item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <BorderLinearProgress variant="determinate" value={50} />
                            </Grid2>
                        </Grid2>
                    </Card>
                    <Grid2 mb={1} mt={1} container item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid2 item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Stack
                                display={"flex"}
                                justifyContent={"space-between"}
                                flexDirection={"row"}
                            >
                                <Typography
                                    gutterBottom
                                    variant="subtitle2"
                                    fontSize={17}
                                    component="div"
                                    fontWeight={"bold"}
                                    color={"grey"}
                                >
                                    Packages
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="subtitle2"
                                    fontSize={17}
                                    component="div"
                                    fontWeight={"bold"}
                                    color={"grey"}
                                >
                                    Collapse all
                                </Typography>
                            </Stack>
                        </Grid2>
                    </Grid2>
                    <Grid2 spacing={1} container item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {[...Array(2)].map((item, index) => (
                            <Grid2 item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Grid2 item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            <Stack
                                                display={"flex"}
                                                justifyContent={"space-between"}
                                                flexDirection={"row"}
                                            >
                                                <Typography>Northwest Elevation</Typography>
                                                <Typography marginRight={2}>1/3</Typography>
                                            </Stack>
                                        </Grid2>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormControl
                                            sx={{
                                                width: "100% !important",
                                            }}
                                        >
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={selectedValue}
                                                onChange={handleChange}
                                            >
                                                <Stack spacing={1} divider={<Divider />}>
                                                    <FormControlLabel
                                                        value="female"
                                                        control={<Radio />}
                                                        label="AP300 Panels - Crating"
                                                    />

                                                    <FormControlLabel
                                                        value="male"
                                                        control={
                                                            <Radio
                                                                checkedIcon={
                                                                    <CheckCircleIcon
                                                                        style={{
                                                                            color: "green",
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        checked={true}
                                                        label={
                                                            <Box>
                                                                <ClickableText
                                                                    onClick={() => {
                                                                        setModelOpen(true);
                                                                    }}
                                                                    variant="body1"
                                                                >
                                                                    AP300 Panels - CNC
                                                                </ClickableText>
                                                                <ClickableText
                                                                    variant="caption"
                                                                    color="textSecondary"
                                                                >
                                                                    Submitted: August 10, 2024 3:50
                                                                    pm
                                                                </ClickableText>
                                                            </Box>
                                                        }
                                                    />

                                                    <FormControlLabel
                                                        value="male"
                                                        slots={<Typography>Hello</Typography>}
                                                        checked={true}
                                                        control={
                                                            <Radio
                                                                checkedIcon={
                                                                    <ErrorIcon
                                                                        style={{
                                                                            color: "#ffbc0a",
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <Box>
                                                                <Typography variant="body1">
                                                                    AP300 Panels - Fabrication
                                                                </Typography>
                                                                <Typography
                                                                    variant="caption"
                                                                    fontStyle={"italic"}
                                                                    color="textSecondary"
                                                                >
                                                                    Draft: September 19, 2024 10:37
                                                                    am
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    />
                                                </Stack>
                                            </RadioGroup>
                                        </FormControl>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid2>
                        ))}
                    </Grid2>
                </div>
            </div>

            <ClientQualityControlModel
                isOpen={modelOpen}
                onClose={() => {
                    setModelOpen(false);
                }}
            />
        </>
    );
}
