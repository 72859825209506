import React, { useEffect, useRef, useState } from "react";
import "react-voice-recorder/dist/index.css";
import { Mp3Encoder } from "lamejs";
import { IconButton, Modal, Typography } from "@mui/material";
import {
  fetchClientTake5,
  saveClientTake5,
  saveClientTake5Recordings,
} from "../../../api/ClientApi";
import { LoadingButton } from "@mui/lab";
import PauseIcon from "@mui/icons-material/PauseRounded";
import PlayIcon from "@mui/icons-material/PlayArrowRounded";
import StopIcon from "@mui/icons-material/StopRounded";
import RecordIcon from "@mui/icons-material/FiberManualRecord";
import DeleteIcon from "@mui/icons-material/Delete";
import { Close } from "@material-ui/icons";
import moment from "moment";
import StopRoundedIcon from "@mui/icons-material/StopRounded";

const AudioRecorder = ({ open, onClose, jobData, reLoadData }) => {
  const [currentState, setCurrentState] = useState("Home");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const audioChunksRef = useRef([]);
  const [audioBlob, setAudioBlob] = useState(null);
  const CLIENTCOLOR = localStorage?.clientAccentColor;
  const canvasRef = useRef(null);
  const audioRef = useRef(null);
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const [duration, setDuration] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [blobMp3, setBlobMp3] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const recordingInterval = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const bufferLengthRef = useRef(0);
  const animationIdRef = useRef(null);
  const storedWaveform = useRef([]);
  const lastFrameTime = useRef(0);
  const frameCount = useRef(0);
  const animationSpeed = 0.6; // Control the speed of animation

  useEffect(() => {
    drawWaveform();
    return () => {
      if (animationIdRef.current) {
        cancelAnimationFrame(animationIdRef.current);
      }
    };
  }, [isPaused]);

  useEffect(() => {
    if (currentTime === duration) {
      setIsPlaying(false);
    }
  }, [currentTime]);

  useEffect(() => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
      handleConvertToMp3();
    }
  }, [audioBlob]);

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/webm" });
        setAudioBlob(recordedBlob);
        chunks.current = [];
      };
      mediaRecorder.current.start();
      setRecordingTime(0);
      startRecordingTimer();
      startVisualizer(stream);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const handlePauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      clearInterval(recordingInterval.current);
      setIsPaused(true);
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
      clearInterval(recordingInterval.current);
    }

    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
    analyserRef.current.disconnect();
  };

  const handleResumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
      setIsPaused(false);
      startRecordingTimer();
    }
  };

  const startVisualizer = (stream) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const source = audioContext.createMediaStreamSource(stream);
    analyserRef.current = audioContext.createAnalyser();
    analyserRef.current.fftSize = 2048; // Higher fftSize for smooth waveform
    bufferLengthRef.current = analyserRef.current.frequencyBinCount;
    dataArrayRef.current = new Uint8Array(bufferLengthRef.current);

    source.connect(analyserRef.current);
    drawWaveform();
  };

  const drawWaveform = () => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    const midHeight = height / 2;
    const redLinePosition = width / 2;

    const updateFrequency = 6;
    const renderFrame = (currentTime) => {
      if (!analyserRef.current) return;
      if (isPaused) {
        animationIdRef.current = requestAnimationFrame(renderFrame);
        return;
      }
      frameCount.current++;
      const deltaTime = (currentTime - lastFrameTime.current) * animationSpeed;
      if (frameCount.current % updateFrequency === 0 && deltaTime >= 16) {
        analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

        ctx.clearRect(0, 0, width, height);
        ctx.beginPath();
        ctx.moveTo(redLinePosition, 0);
        ctx.lineTo(redLinePosition, height);
        ctx.strokeStyle = "red";
        ctx.lineWidth = 1;
        ctx.stroke();

        ctx.fillStyle = "#a8a8a8";
        const barWidth = 2;
        const gapWidth = 3;
        const totalWidth = barWidth + gapWidth;
        let x = redLinePosition;
        const rightSideBars = [];
        for (let i = 0; i < bufferLengthRef.current && x < width; i++) {
          const value = dataArrayRef.current[i];
          const barHeight = 17;

          ctx.fillRect(x, midHeight - barHeight / 2, barWidth, barHeight);

          const actualIntensity = value / 255;
          rightSideBars.push(actualIntensity);
          x += totalWidth;
        }

        if (rightSideBars.length > 0) {
          const newIntensity = rightSideBars[0];
          const variableHeight = newIntensity * height * 0.8;
          storedWaveform.current.unshift({
            height: variableHeight,
            intensity: newIntensity * (0.8 + Math.random() * 0.4),
          });

          if (storedWaveform.current.length * totalWidth > redLinePosition) {
            storedWaveform.current.pop();
          }
        }

        let xLeft = redLinePosition - totalWidth;
        for (let i = 0; i < storedWaveform.current.length && xLeft > 0; i++) {
          const { height: barHeight, intensity } = storedWaveform.current[i];
          const dynamicHeight = barHeight * intensity * 3;

          ctx.fillRect(
            xLeft,
            midHeight - dynamicHeight / 2,
            barWidth,
            dynamicHeight
          );
          xLeft -= totalWidth;
        }
        lastFrameTime.current = currentTime;
      }
      animationIdRef.current = requestAnimationFrame(renderFrame);
    };
    animationIdRef.current = requestAnimationFrame(renderFrame);
  };

  const handleConvertToMp3 = async () => {
    await convertToMp3(audioBlob);
    audioChunksRef.current = [];
  };

  const convertToMp3 = async (audioBlob) => {
    // console.log("audioBlob-->", audioBlob);
    const reader = new FileReader();

    reader.onloadend = async () => {
      const arrayBuffer = reader.result;
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      const samples = audioBuffer.getChannelData(0);
      const samplesInt16 = new Int16Array(samples.length);

      for (let i = 0; i < samples.length; i++) {
        samplesInt16[i] =
          samples[i] < 0 ? samples[i] * 0x8000 : samples[i] * 0x7fff;
      }

      const mp3Encoder = new Mp3Encoder(1, audioBuffer.sampleRate, 128);
      const mp3Data = [];
      let mp3Buf = mp3Encoder.encodeBuffer(samplesInt16);
      if (mp3Buf.length > 0) {
        mp3Data.push(mp3Buf);
      }

      mp3Buf = mp3Encoder.flush();
      if (mp3Buf.length > 0) {
        mp3Data.push(mp3Buf);
      }

      const mp3Blob = new Blob(mp3Data, { type: "audio/mp3" });

      setBlobMp3(mp3Blob);
      const mp3Url = URL.createObjectURL(mp3Blob);
      // console.log("mp3url---->", mp3Url);
      setAudioUrl(mp3Url);
    };

    reader.readAsArrayBuffer(audioBlob);
  };

  const startRecordingTimer = () => {
    recordingInterval.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const handleLoadedMetadata = (e) => {
    setDuration(audioRef.current.duration);
  };

  const handleSeek = (e) => {
    const time = e.target.value;
    audioRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    // console.log("currenttime ... :", audioRef.current.currentTime);
    if (audioRef.current.currentTime === audioRef.current.duration) {
    }
  };

  const onSavePressed = async () => {
    if (!(audioBlob instanceof Blob)) {
      console.error("audioBlob is not a Blob:", audioBlob);
      return;
    }
    setSaveLoading(true);

    try {
      const userId = localStorage.getItem("userId");
      const fetchTake5obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: true,
        SearchList: [
          {
            clientEmployeeJobId: jobData?.id,
          },
        ],
      };
      const res = await fetchClientTake5(fetchTake5obj);

      const obj = {
        UserId: Number(userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: res.data[0]?.id || 0,
            ClientEmployeeId: localStorage?.clientEmployeeId,
            ClientEmployeeJobId: jobData?.id,
            ModifiedBy: Number(userId),
          },
        ],
      };
      const result = await saveClientTake5(obj);

      const formData = new FormData();
      formData.append("Id", 0);
      formData.append("ClientTake5Id", Number(result.data[0].objData.id) || 1);
      formData.append("FileName", blobMp3, "file.mp3");
      formData.append("ModifiedBy", Number(userId));

      const recordingResult = await saveClientTake5Recordings(formData);
      // console.log("Recording saved successfully:", recordingResult);
      reLoadData();
    } catch (error) {
      console.log("Error saving recording:", error);
    } finally {
      handelClose();
    }
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const handelClose = () => {
    clearCanvas();
    setDuration(0);
    setCurrentState("Home");
    setAudioBlob(null);
    setBlobMp3(null);
    setAudioUrl(null);
    setRecordingTime(0);
    animationIdRef.current = null;
    canvasRef.current = null;
    audioRef.current = null;
    mediaStream.current = null;
    mediaRecorder.current = null;
    setIsPaused(false);
    chunks.current = [];
    onClose();
    try {
      if (mediaRecorder.current.currentState === "recording") {
        handleStopRecording();
      }
    } catch (e) { }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-center">
          <div
            style={{
              padding: "20px",
              width: "500px",
            }}
            className="modal-card"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                color="primary"
                onClick={handelClose}
                style={{ padding: "0px" }}
              >
                <Close />
              </IconButton>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "30px 0",
              }}
            >
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  1. Check surrounding area
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  2. Input timesheet
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  3. Check weather
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  4. Report any immediate danger
                </Typography>
              </span>
            </div>

            {currentState === "Home" && (
              <div
                style={{
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: "lightgrey",
                  }}
                >
                  Record a message
                </Typography>
              </div>
            )}
            {currentState === "Recording" && (
              <div
                style={{
                  height: "200px",
                }}
              >
                <canvas
                  ref={canvasRef}
                  style={{
                    width: "100%",
                    height: "170px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "10px 0",
                    gap: "5px",
                  }}
                >
                  <div
                    className={!isPaused ? "pulse" : ""}
                    style={{
                      backgroundColor: "red",
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <Typography>
                    {moment.utc(recordingTime * 1000).format("m:ss")}
                  </Typography>
                </div>
              </div>
            )}
            {currentState === "Playback" && (
              <div
                style={{
                  height: "150px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <input
                      style={{
                        width: "100%",
                        margin: "0 10px",
                      }}
                      type="range"
                      max={duration}
                      value={currentTime}
                      onChange={handleSeek}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div> {moment.utc(currentTime * 1000).format("m:ss")}</div>
                    <div>{moment.utc(duration * 1000).format("m:ss")}</div>
                  </div>
                </div>
                <audio
                  type="audio/mp3"
                  hidden
                  controls
                  onTimeUpdate={handleTimeUpdate}
                  onLoadedMetadata={handleLoadedMetadata}
                  name="media"
                  ref={audioRef}
                  src={audioUrl}
                ></audio>
              </div>
            )}

            {currentState === "Home" && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <IconButton
                  onClick={() => {
                    handleStartRecording();
                    setCurrentState("Recording");
                  }}
                  style={{
                    backgroundColor: "#ebebeb",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    padding: "0px",
                  }}
                >
                  <RecordIcon
                    style={{
                      height: "70px",
                      width: "70px",
                      color: "red",
                    }}
                  />
                </IconButton>
              </div>
            )}
            {currentState === "Recording" && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                {!isPaused ? (
                  <IconButton
                    onClick={() => {
                      handlePauseRecording();
                    }}
                    style={{
                      backgroundColor: "#ebebeb",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <PauseIcon
                      style={{
                        fontSize: "50px",
                        color: "red",
                      }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      handleResumeRecording();
                    }}
                    style={{
                      backgroundColor: "#ebebeb",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <RecordIcon
                      style={{
                        fontSize: "50px",
                        color: "red",
                      }}
                    />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => {
                    handleStopRecording();
                    setCurrentState("Playback");
                  }}
                  style={{
                    backgroundColor: "#ebebeb",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                  }}
                >
                  <StopIcon
                    style={{
                      fontSize: "50px",
                      color: "red",
                    }}
                  />
                </IconButton>
              </div>
            )}
            {currentState === "Playback" && (
              <>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    flexDirection: "column",
                  }}
                >
                  {isPlaying ? (
                    <IconButton
                      onClick={() => {
                        // audioRef.current.currentTime = 0;
                        audioRef.current.pause();
                        setIsPlaying(false);
                      }}
                      style={{
                        backgroundColor: "#ebebeb",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <StopRoundedIcon
                        style={{
                          height: "50px",
                          width: "50px",
                          color: "red",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        audioRef.current.currentTime = 0;
                        audioRef.current.play();
                        setIsPlaying(true);
                      }}
                      style={{
                        backgroundColor: "#ebebeb",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <PlayIcon
                        style={{
                          fontSize: "50px",
                        }}
                      />
                    </IconButton>
                  )}

                  <IconButton
                    onClick={() => {
                      setCurrentState("Home");
                      setIsPaused(false);
                      clearCanvas();
                    }}
                    style={{
                      backgroundColor: "#ebebeb",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      width: "70px",
                      height: "70px",
                    }}
                  >
                    <DeleteIcon
                      style={{
                        fontSize: "50px",
                      }}
                    />
                  </IconButton>
                </div>
                <LoadingButton
                  onClick={onSavePressed}
                  loading={saveLoading}
                  loadingPosition="start"
                  fullWidth
                  style={{
                    textTransform: "none",
                    backgroundColor: CLIENTCOLOR,
                    color: "white",
                    marginTop: "20px",
                    borderRadius: "10px",
                  }}
                >
                  save
                </LoadingButton>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AudioRecorder;
